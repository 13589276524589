import React, { useEffect } from 'react';
import { Agent } from '../../../../../../../@Types/@Types';
import { RootState } from '../../../../../../../utils/_store';
import styles from './Assign.module.css';
import SelectedAgentComponent from './SelectedAgent/SelectedAgent';
import AgentComponent from './Agent/Agent';
import { useSelector, useDispatch } from 'react-redux';
import {
    pageScroll,
    selectElements,
    setChanges,
} from '../../../../../../../controllers/TicketsController/AssignAgents/AgentsActions';
import List from '../../../../../../../shared/MenuList/MenuList';
import { AssignableAgent } from '../../../../../../../@Types/Agent';
interface AssignProps {
    /** The ref of the list, to scroll to top from parent */
    listRef: any;
}
function Assign({ listRef }: AssignProps): JSX.Element {
    const dispatch = useDispatch();
    const pageInfo = useSelector(
        (state: RootState) => state.ticketAssignAgents
    );

    useEffect(() => {
        if (listRef?.current?.scrollTop) {
            listRef.current.scrollTop = 0;
        }
    }, []);

    /** Store changes in object of changes in the reducer */
    const change = (agent: Agent, assigned: boolean): void => {
        const tempChanges = { ...pageInfo.changes };
        tempChanges[agent._id] = assigned;
        dispatch(setChanges(tempChanges));
        const agents = [...pageInfo.assignedAgents];
        const withoutAgent = agents.filter((agnt) => agnt._id !== agent._id);
        if (assigned && withoutAgent.length === agents.length) {
            agents.push(agent);
            dispatch(
                selectElements({
                    assigned: agents,
                    elements: pageInfo.elements.map((agt) =>
                        agt._id === agent._id ? { ...agt, assigned: true } : agt
                    ),
                })
            );
        }
        if (!assigned && withoutAgent.length < agents.length) {
            dispatch(
                selectElements({
                    assigned: withoutAgent,
                    elements: pageInfo.elements.map((agt) =>
                        agt._id === agent._id
                            ? { ...agt, assigned: false }
                            : agt
                    ),
                })
            );
        }
    };

    /**
     * Function that renders the lists agents, if search is active the selected agents
     * will be search in the browser and the rest in the backend.
     */
    const renderAgents = (): JSX.Element => {
        let assignedAgents: JSX.Element[] = [];
        if (pageInfo.search) {
            const regex = new RegExp(pageInfo.search, 'gi');
            assignedAgents = pageInfo.assignedAgents
                .filter(
                    (agent) =>
                        regex.test(agent.name) ||
                        regex.test(agent.email) ||
                        regex.test(agent.jobTitle)
                )
                .map((agent, index) => (
                    <SelectedAgentComponent
                        agent={agent}
                        key={index}
                        handleRemove={(): void => {
                            change(agent, false);
                        }}
                        first={index === 0}
                        onlySelected={pageInfo.assignedAgents.length == 1}
                    />
                ));
        } else {
            assignedAgents = pageInfo.assignedAgents.map((agent, index) => (
                <SelectedAgentComponent
                    agent={agent}
                    key={index}
                    handleRemove={(): void => {
                        change(agent, false);
                    }}
                    first={index === 0}
                    onlySelected={pageInfo.assignedAgents.length === 1}
                />
            ));
        }
        let first = assignedAgents.length;
        const notAssigned = pageInfo.elements.map(
            (agent: AssignableAgent, index): JSX.Element | void => {
                if (!agent.assigned)
                    return (
                        <AgentComponent
                            first={first++ === 0}
                            agent={agent}
                            key={index}
                            handleClick={(): void => {
                                change(agent, true);
                            }}
                        />
                    );
            }
        );
        return (
            <React.Fragment>
                {assignedAgents}
                {notAssigned}
                {assignedAgents.length === 0 && notAssigned.length === 0 && (
                    <div className={styles.emptyContainer}>No hay agentes</div>
                )}
            </React.Fragment>
        );
    };
    return (
        <div className={styles.container}>
            <List
                noMoreElements={pageInfo.noMoreElements}
                listRef={listRef}
                onPageScroll={(): void => {
                    dispatch(pageScroll());
                }}
                loading={pageInfo.loading}
                elements={pageInfo.elements}
            >
                {renderAgents()}
            </List>
        </div>
    );
}
export default Assign;
