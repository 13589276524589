import styles from './StatesMenu.module.css';
import React, { useRef } from 'react';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import Dialog from '../../Dialog/Dialog';
import {
    ClickAwayListener,
    Popper,
    PopperPlacementType,
} from '@material-ui/core';
import { State } from '../../../@Types/State';

interface StatesMenuProps {
    states: State[];
    anchorRef: any;
    onClose: Function;
    selectedStates: State[];
    /** If the app is mobile */
    mobile?: boolean;
    handleUpdate: Function;
    multiple?: boolean;
    placement?: PopperPlacementType;
    canClose?: boolean;
    canChange?: boolean;
    zIndex?: number;
}
function StatesMenu({
    states,
    onClose,
    multiple = false,
    canClose = true,
    canChange = true,
    mobile = false,
    anchorRef,
    handleUpdate,
    selectedStates,
    placement = 'bottom-end',
    zIndex = 3005,
}: StatesMenuProps): JSX.Element {
    const emptyRef = useRef<HTMLDivElement>(null);

    const renderContent = (): JSX.Element => {
        return (
            <div
                className={styles.emptyContainer}
                onClick={(e): void => {
                    if (emptyRef.current === e.target) {
                        onClose();
                    }
                }}
                ref={emptyRef}
            >
                <div className={styles.container}>
                    {states.map((state, index) => (
                        <div
                            key={index}
                            className={
                                (state.type === 'CLOSED' && canClose) ||
                                (state.type !== 'CLOSED' && canChange)
                                    ? styles.optionSelectableContainer
                                    : styles.optionContainer
                            }
                            style={{
                                borderBottom:
                                    index == states.length - 1
                                        ? 'none'
                                        : '1px solid var(--light-grey)',
                            }}
                            onClick={(): void => {
                                if (
                                    (state.type === 'CLOSED' && canClose) ||
                                    (state.type !== 'CLOSED' && canChange)
                                ) {
                                    if (multiple) {
                                        const without = selectedStates.filter(
                                            (pState) => pState._id !== state._id
                                        );
                                        if (
                                            without.length !==
                                            selectedStates.length
                                        ) {
                                            handleUpdate(without);
                                        } else {
                                            handleUpdate([
                                                ...selectedStates,
                                                state,
                                            ]);
                                        }
                                    } else {
                                        handleUpdate(state);
                                    }
                                } else {
                                    //TODO: manejo de errores, no puedes cerrar esto
                                }
                            }}
                        >
                            <div className={styles.left}>
                                <div className={styles.top}>
                                    <div className={styles.checkIcon}>
                                        {selectedStates.find(
                                            (pState) =>
                                                pState?._id === state._id
                                        ) && (
                                            <CheckRoundedIcon fontSize="inherit" />
                                        )}
                                    </div>
                                    <span
                                        className={styles.rootLbl}
                                        style={
                                            (state.type === 'CLOSED' &&
                                                canClose) ||
                                            (state.type !== 'CLOSED' &&
                                                canChange)
                                                ? { cursor: 'pointer' }
                                                : {
                                                      cursor: 'default',
                                                      color: 'var(--greyBtn)',
                                                  }
                                        }
                                    >
                                        {state.label}
                                    </span>
                                </div>
                                {state.description &&
                                    state.description.trim() !== '' && (
                                        <div className={styles.bottom}>
                                            {state.description}
                                        </div>
                                    )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    if (mobile) {
        return (
            <Dialog
                onClose={(): void => {
                    onClose();
                }}
                maxWidth="100vw"
                border={10}
                transparent
            >
                {renderContent()}
            </Dialog>
        );
    } else {
        return (
            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={(): void => {
                    onClose();
                }}
            >
                <Popper
                    open={true}
                    anchorEl={anchorRef.current}
                    placement={placement}
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            priority: ['top', 'bottom', 'left', 'right'],
                            boundariesElement: 'viewport',
                            padding: 20,
                        },
                    }}
                    style={{ zIndex }}
                >
                    {renderContent()}
                </Popper>
            </ClickAwayListener>
        );
    }
}

export default StatesMenu;
