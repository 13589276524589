import styles from './TicketDetail.module.css';
import ActionBar from './ActionBar/ActionBar';
import Sidebar from './SideBar/SideBar';
import ContentList from './ContentList/ContentList';
import React from 'react';
import { Ticket } from '../../../@Types/@Types';
import TicketTitle from './ContentList/TicketTitle/TicketTitle';
import RoundedIconButton from '../../../shared/RoundedIconButton/RoundedIconButton';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ContentCard from './ContentList/ContentCard/ContentCard';
import MobileContentList from './MobileContentDialog/MobileContentDialog';
import { checkTicketPerm } from '../../../utils/PermissionsFunctions';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
interface TicketDetailProps {
    /** The currently displayed ticket */
    selectedElement?: Ticket;
    /** Function to call when mobile wants to handle return to list */
    handleReturnToList?: Function;
    /** If the app is mobile */
    mobile: boolean;
}
function TicketDetail({
    selectedElement,
    handleReturnToList,
    mobile,
}: TicketDetailProps): JSX.Element {
    const user = useSelector((state: RootState) => state.site.user);
    const isClosed = selectedElement?.state?.type === 'CLOSED';
    const canComment = checkTicketPerm(user, selectedElement, null);
    if (!mobile && selectedElement) {
        return (
            <div className={styles.container}>
                <div className={styles.containerTop}>
                    <ContentList
                        canComment={canComment}
                        selectedElement={selectedElement}
                    />
                    <Sidebar
                        layoutIsMobile={mobile}
                        selectedElement={selectedElement}
                    />
                </div>
                <ActionBar
                    isClosed={isClosed}
                    selectedElement={selectedElement}
                />
            </div>
        );
    } else if (selectedElement) {
        return (
            <React.Fragment>
                <div className={styles.mobileContainer}>
                    {handleReturnToList && (
                        <div className={styles.returnButton}>
                            <RoundedIconButton
                                backgroundColor="var(--secondary)"
                                color="white"
                                onClick={(): void => {
                                    handleReturnToList();
                                }}
                            >
                                <ArrowBack />
                            </RoundedIconButton>
                        </div>
                    )}
                    <TicketTitle
                        layoutIsMobile={mobile}
                        selectedElement={selectedElement}
                        interactions={false}
                        setInteractions={(): void => {}}
                    />
                    <ContentCard
                        content={selectedElement.content}
                        layoutIsMobile={mobile}
                    />
                    <Sidebar
                        layoutIsMobile={mobile}
                        selectedElement={selectedElement}
                    />
                </div>
                <MobileContentList
                    isClosed={isClosed}
                    selectedElement={selectedElement}
                />
            </React.Fragment>
        );
    }
    return <div></div>;
}

export default TicketDetail;
