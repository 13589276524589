import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Client,
    Entity,
    EntityValue,
    Ticket,
} from '../../../../../../@Types/@Types';
import ActionsService from '../../../../../../controllers/TicketsController/Actions/ActionsService';
import { refreshCurrent } from '../../../../../../controllers/TicketsController/TicketsActions';
import Dialog from '../../../../../../shared/Dialog/Dialog';
import { RootState } from '../../../../../../utils/_store';
import EntityRenderer from '../../../../../../shared/EntityRenderer/EntityRenderer';
import styles from './TicketEntity.module.css';
import SwapHorizRoundedIcon from '@material-ui/icons/SwapHorizRounded';
import { checkTicketPerm } from '../../../../../../utils/PermissionsFunctions';
import { updateEntityValue } from '../../../../../../controllers/EntityValuesController/EntityValuesService';
import EntityValuesMenu from '../../../../../../shared/@Menus/EntityValuesMenu/EntityValuesMenu';
import EntityValueClientComponent from '../../../../../EntityValues/EntityValueDetail/EntityValueClients/EntityValueClientComponent/EntityValueClientComponent';
import { useCurrentProject } from '../../../../../../hooks';
interface TicketEntityProps {
    ticket: Ticket;
    client: Client | null;
    entity: Entity;
}

function TicketEntity({
    ticket,
    client,
    entity,
}: TicketEntityProps): JSX.Element {
    const user = useSelector((state: RootState) => state.site.user);
    const project = useCurrentProject();
    const [showEntity, setShowEntity] = useState(false);
    const dispatch = useDispatch();
    const entityValues = ticket.entities?.[entity._id] ?? [];
    const entityRef = useRef<HTMLDivElement>(null);
    const projectEntity = project.entities.find(
        (pEntity) => pEntity.idEntity === project.idPrimaryEntity
    );
    const canEdit =
        checkTicketPerm(user, ticket, null) && projectEntity?.manual === true;
    if (entityValues.length > 0) {
        const entityValue = entityValues[0];
        return (
            <React.Fragment>
                {showEntity && (
                    <Dialog
                        key={'EntityMenu'}
                        maxWidth={'100%'}
                        disableEnforceFocus
                        onClose={(): void => {
                            setShowEntity(false);
                            dispatch(refreshCurrent());
                        }}
                    >
                        <EntityDialog
                            canEdit={canEdit}
                            multiple={projectEntity?.multiple === true}
                            ticket={ticket}
                            entity={entity}
                            client={client}
                            entityValues={entityValues}
                            onSubmit={async (
                                pEntityValue,
                                reload
                            ): Promise<void> => {
                                await updateEntityValue(
                                    entity._id,
                                    entityValue._id,
                                    {
                                        values: pEntityValue.values,
                                    }
                                );
                                dispatch(refreshCurrent());
                                reload();
                            }}
                        />
                    </Dialog>
                )}
                <span
                    onClick={(): void => {
                        setShowEntity(true);
                    }}
                    className={styles.entity}
                    key={'Entity'}
                >
                    {entityValue?.values?.[entity.idLabelStep] ?? 'Sin Nombre'}
                </span>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                {showEntity && (
                    <EntityValuesMenu
                        entity={entity}
                        anchorRef={entityRef}
                        handleClose={(): void => {
                            setShowEntity(false);
                        }}
                        multiple={projectEntity?.multiple}
                        elementsToOmit={
                            projectEntity?.multiple ? undefined : entityValues
                        }
                        initialElements={
                            projectEntity?.multiple ? entityValues : undefined
                        }
                        confirm={'Seleccionar'}
                        handleConfirm={async (
                            entityValue: EntityValue | EntityValue[]
                        ): Promise<void> => {
                            try {
                                await ActionsService.setEntity(
                                    ticket.idProject,
                                    ticket._id,
                                    entity._id,
                                    (Array.isArray(entityValue)
                                        ? entityValue
                                        : [entityValue]
                                    ).map((entityValue) => entityValue._id)
                                );
                                dispatch(refreshCurrent());
                                setShowEntity(false);
                            } catch (error) {
                                console.error(error);
                            }
                        }}
                    />
                )}
                <span
                    key={'EntityValue'}
                    onClick={(): void => {
                        if (canEdit) setShowEntity(true);
                    }}
                    className={
                        canEdit ? styles.entity + '  noselect' : undefined
                    }
                    title={'Seleccionar ' + entity.name}
                    ref={entityRef}
                    style={{ fontWeight: 700 }}
                >
                    (Sin {entity.name})
                </span>
            </React.Fragment>
        );
    }
}

export default TicketEntity;
interface EntityDialogProps extends TicketEntityProps {
    canEdit: boolean;
    multiple: boolean;
    entity: Entity;
    entityValues: EntityValue[];
    onClose?: Function;
    onSubmit: (
        entity: Partial<EntityValue>,
        setLoading: Function
    ) => Promise<void>;
}
function EntityDialog({
    client,
    multiple,
    canEdit,
    entity,
    entityValues,
    ticket,
    onClose,
    onSubmit,
}: EntityDialogProps): JSX.Element {
    const [showEntityValueMenu, setShowEntityValueMenu] = useState(false);
    const switchRef = useRef<HTMLDivElement>(null);
    const siteInfo = useSelector((state: RootState) => state.site);
    const dispatch = useDispatch();
    const [localClient, setLocalClient] = useState(client);

    useEffect(() => {
        setLocalClient(client);
    }, [client]);

    return (
        <div className={styles.container}>
            {showEntityValueMenu && (
                <EntityValuesMenu
                    zIndex={1301}
                    entity={entity}
                    anchorRef={switchRef}
                    handleClose={(): void => {
                        setShowEntityValueMenu(false);
                    }}
                    title={
                        'Cambiar ' +
                        (multiple ? entity.pluralName : entity.name)
                    }
                    initialElements={entityValues}
                    placement="bottom-end"
                    multiple={multiple}
                    confirm={'Cambiar'}
                    handleConfirm={async (
                        entityValue: EntityValue | EntityValue[]
                    ): Promise<void> => {
                        try {
                            if (siteInfo.idProject) {
                                await ActionsService.setEntity(
                                    siteInfo.idProject,
                                    ticket._id,
                                    entity._id,
                                    (Array.isArray(entityValue)
                                        ? entityValue
                                        : [entityValue]
                                    ).map((entityValue) => entityValue._id)
                                );
                            }
                            dispatch(refreshCurrent());
                            onClose?.();
                            setShowEntityValueMenu(false);
                        } catch (error) {
                            console.error(error);
                        }
                    }}
                />
            )}
            <div
                className={styles.entityContainer}
                style={{
                    width:
                        ((entity.size?.blockSize ?? 3) +
                            (entity.size?.spacingSize ?? 20)) *
                            (entity.size?.blockNum ?? 210) +
                        40,
                }}
            >
                <EntityRenderer
                    entity={entity}
                    entityValue={{ ...entityValues[0] }}
                    customSend={onSubmit}
                    editable={false}
                    customNameBtns={
                        <React.Fragment>
                            {canEdit && (
                                <div
                                    className={styles.swapIcon}
                                    ref={switchRef}
                                    onClick={(): void => {
                                        setShowEntityValueMenu(true);
                                    }}
                                    title={
                                        'Cambiar ' +
                                        (multiple
                                            ? entity.pluralName
                                            : entity.name)
                                    }
                                >
                                    <SwapHorizRoundedIcon
                                        fontSize="inherit"
                                        style={{ marginTop: '-2px' }}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    }
                />
            </div>

            <div className={styles.clientTitle}>Cliente:</div>
            {localClient && (
                <div className={styles.entityValueClient}>
                    <EntityValueClientComponent
                        client={localClient}
                        entity={entity}
                        editable={true}
                        idEntityValue={entityValues[0]._id}
                        handleUpdate={(client: any): void => {
                            setLocalClient(client);
                        }}
                    />
                </div>
            )}
        </div>
    );
}
