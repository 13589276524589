import styles from './AgentsSideBar.module.css';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Avatar from '../../../../../shared/Avatar/Avatar';
import { useState, useRef } from 'react';
import { Ticket } from '../../../../../@Types/@Types';
import AgentsMenu from './AgentsMenu/AgentsMenu';

interface AgentsSideBarProps {
    /** The currently selected ticket */
    selectedElement: Ticket;
    /** If the current ticket is can be modified */
    canChange: boolean;
    /** If the layout is mobile */
    layoutIsMobile?: boolean;
}
function AgentsSideBar({
    canChange,
    layoutIsMobile,
    selectedElement,
}: AgentsSideBarProps): JSX.Element {
    const [hovering, setHovering] = useState(false);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const headerRef = useRef<HTMLDivElement>(null);
    return (
        <div className={styles.container}>
            {menuOpen && (
                <AgentsMenu
                    layoutIsMobile={layoutIsMobile}
                    selectedElement={selectedElement}
                    anchorRef={headerRef}
                    handleClose={(): void => {
                        setMenuOpen(false);
                    }}
                />
            )}
            <div
                ref={headerRef}
                className={styles.header + ' noselect'}
                data-testid="AgentsSideBar_header"
                onMouseOver={(): void => {
                    if (!menuOpen && canChange) {
                        setHovering(true);
                    }
                }}
                onMouseLeave={(): void => {
                    setHovering(false);
                }}
                onClick={(): void => {
                    if (canChange) {
                        setMenuOpen(true);
                        setHovering(false);
                    }
                }}
                style={canChange ? { cursor: 'pointer' } : {}}
            >
                <label
                    className={styles.titleLbl}
                    style={{
                        color: hovering ? 'var(--contrast)' : 'var(--accent)',
                        cursor: canChange ? 'pointer' : 'default',
                    }}
                >
                    Agentes
                </label>
                {canChange && (
                    <div
                        className={styles.editContainer}
                        style={{
                            color: hovering
                                ? 'var(--contrast)'
                                : 'var(--accent)',
                        }}
                    >
                        <EditRoundedIcon fontSize="inherit" />
                    </div>
                )}
            </div>
            {/* TODO: Decidir si sigue creciendo hacia abajo */}
            <div
                className={styles.agentsContainer}
                data-testid="AgentsSideBar_agentsContainer"
            >
                {selectedElement.agents.map((agent, index) => (
                    <div className={styles.agentContainer} key={index}>
                        <Avatar
                            lbl={agent?.name}
                            img={agent?.img}
                            toolTip={false}
                        />
                        <label className={styles.nameLbl}>{agent?.name}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}
export default AgentsSideBar;
