import {
    AnyAction,
    EntityState,
    PayloadAction,
    createAction,
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
    createSlice,
    nanoid,
} from '@reduxjs/toolkit';
import {
    ContentState,
    convertFromRaw,
    convertToRaw,
    EditorState,
    Modifier,
    RawDraftContentBlock,
    RawDraftEntity,
    SelectionState,
} from 'draft-js';
import { Payload, PayloadFile, PayloadImage } from '../../@Types/Payload';
import produce from 'immer';
import { createContext, useContext } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../utils/_store';
import {
    PayloadEditorFile,
    PayloadEditorImage,
    PayloadEditorStateTypes,
    PayloadEditorTemplateContext,
} from './PayloadEditorTypes';
import {
    draftRawTrim,
    invertDraftRawTrim,
    stringToDraft,
} from '../../utils/draftFunctions';
import { Template } from '../../@Types/Template';
import {
    DraftEntityData,
    MappableDraftEntityData,
    isMappableEntityData,
} from '../../@Types/Draft/DraftEntityData';
import { OrderedSet } from 'immutable';
import { DraftStyleTypes } from '../../constants/Draft/DraftStyleTypes';
import { Entity } from '../../@Types/@Types';
import { Concept, ProjectEntity } from '../../@Types/Project';
import {
    DraftEntityDataMappingTypes,
    DraftEntityDataTypes,
} from '../../constants/Draft/DraftEntityDataTypes';
import { DraftEntityTypes } from '../../constants/Draft/DraftEntityTypes';
import { EurekaDraft } from '../../@Types/Draft/Draft';
import { EntityDataMapper } from '../../shared/TextEditor/EntityDataMapper';
import { TextEditorTypes } from '../../constants/TextEditorTypes';

export const PayloadEditorContext = createContext<string>(nanoid());

export const usePayloadEditorDispatch: () => AppDispatch = (): AppDispatch => {
    const idEditor = useContext(PayloadEditorContext);
    const dispatch = useDispatch();
    const appDispatch = (action: AnyAction): AnyAction => {
        if (!action.meta) action.meta = { idEditor };
        return dispatch(action);
    };

    return appDispatch as AppDispatch;
};

export const selectContentState = createSelector(
    [
        (state: RootState): Record<string, PayloadEditorState> =>
            state.payloadEditors,
        (state: RootState, idEditor: string): string => idEditor,
    ],
    (state, idEditor) => {
        return state[idEditor]?.editorState.getCurrentContent();
    }
);

export const usePayloadEditorSelector: TypedUseSelectorHook<
    PayloadEditorState
> = (selector: any, options: any) => {
    const idEditor = useContext(PayloadEditorContext);
    return useSelector(
        (state: RootState) =>
            selector(
                state.payloadEditors[idEditor] ?? InitialPayloadEditorState
            ),
        options
    );
};

interface PayloadEditorPreview {
    loading: boolean;
    original: {
        editorState: EditorState;
        images: Record<string, PayloadEditorImage>;
        files: EntityState<PayloadEditorFile>;
    };
}

interface PayloadEditorEditing {
    data: DraftEntityData;
    start: number;
    end: number;
    blockKey: string;
    entityKey: string;
}

export interface PayloadEditorState {
    editorState: EditorState;
    preview?: PayloadEditorPreview;
    templateContext?: PayloadEditorTemplateContext;
    images: Record<string, PayloadEditorImage>;
    files: EntityState<PayloadEditorFile>;
    isDirty: boolean;
    editing?: PayloadEditorEditing;
    hasError: boolean;
}

const filesAdapter = createEntityAdapter<PayloadEditorFile>();

export const PayloadEditorFilesSelectors = filesAdapter.getSelectors(
    (state: PayloadEditorState) => state.files
);

const BaseFilesSelectors = filesAdapter.getSelectors(
    (files: EntityState<PayloadEditorFile>) => files
);

export const InitialPayloadEditorState: PayloadEditorState = {
    editorState: EditorState.createEmpty(),
    images: {},
    files: filesAdapter.getInitialState(),
    isDirty: false,
    hasError: false,
};

export interface ValuesStore {
    global: Record<string, any>;
    sections: Record<string, Record<string, any>>;
}

export const PayloadEditorSlice = createSlice({
    name: 'PayloadEditor',
    initialState: InitialPayloadEditorState,
    reducers: {
        reset: (state, { payload }: PayloadAction<Payload | undefined>) => {
            return {
                ...state,
                isDirty: false,
                isDisabled: false,
                editing: undefined,
                hasError: false,
                editorState: mapEditorState(payload),
                images: mapImages(payload),
                files: mapFiles(payload),
            };
        },
        uploadFiles: (state, {}: PayloadAction<File[]>) => state,
        addFiles: (state, { payload }: PayloadAction<PayloadEditorFile[]>) => {
            filesAdapter.addMany(state.files, payload);
        },
        removeFile: (state, { payload }: PayloadAction<string>) => {
            filesAdapter.removeOne(state.files, payload);
        },
        deleteBlock: (state, { payload: blockKey }: PayloadAction<string>) => {
            const { editorState } = state;
            const contentState = editorState.getCurrentContent();
            const draft = convertToRaw(contentState);
            const blocks = [];
            let key = draft.blocks[0].key;
            for (let i = 0; i < draft.blocks.length; i++) {
                const block = draft.blocks[i];
                if (block.key === blockKey) {
                    for (const entity of block.entityRanges) {
                        delete draft.entityMap[entity.key];
                    }
                    key = draft.blocks[i - 1]?.key ?? draft.blocks[i + 1]?.key;
                } else {
                    blocks.push(block);
                }
            }
            if (blocks.length === 0) {
                state.editorState = EditorState.createEmpty();
                return;
            }
            state.editorState = EditorState.push(
                state.editorState,
                convertFromRaw({ blocks, entityMap: draft.entityMap }),
                'remove-range'
            );
            state.editorState = EditorState.forceSelection(
                state.editorState,
                new SelectionState({
                    anchorKey: key,
                    anchorOffset: length,
                    focusKey: key,
                    focusOffset: length,
                })
            );
        },
        uploadImage: (state, {}: PayloadAction<File>) => state,
        updateImage: (
            state,
            { payload: image }: PayloadAction<PayloadEditorImage>
        ) => {
            if (state.images[image.imageKey])
                state.images[image.imageKey] = image;
            if (state.preview && state.preview.original.images[image.imageKey])
                state.preview.original.images[image.imageKey] = image;
        },
        updateFile: (
            state,
            { payload: file }: PayloadAction<PayloadEditorFile>
        ) => {
            if (state.files.entities[file.id])
                state.files.entities[file.id] = file;
            if (state.preview && state.preview.original.files.entities[file.id])
                state.preview.original.files.entities[file.id] = file;
        },
        updateAtomicEntityData: (
            state,
            {
                payload: { entityKey, entityData },
            }: PayloadAction<{
                entityKey: string;
                entityData: DraftEntityData;
            }>
        ) => {
            const { editorState } = state;
            const contentState = state.editorState.getCurrentContent();
            contentState.mergeEntityData(entityKey, entityData);
            state.editorState = EditorState.push(
                editorState,
                contentState,
                'change-block-data'
            );
        },
        updateEditorState: (state, { payload }: PayloadAction<EditorState>) => {
            state.editorState = payload;
        },
        updateState: (
            state,
            {
                payload,
            }: PayloadAction<
                Partial<
                    Pick<PayloadEditorState, 'images' | 'editorState' | 'files'>
                >
            >
        ) => {
            if (payload.editorState) state.editorState = payload.editorState;
            if (payload.images) state.images = payload.images;
            if (payload.files) state.files = payload.files;
        },
        setDirty: (state) => {
            state.isDirty = true;
        },
        clearPreview: (state) => {
            if (!state.preview || state.preview.loading) return;
            state.editorState = state.preview.original.editorState;
            state.images = state.preview.original.images;
            state.files = state.preview.original.files;
            state.preview = undefined;
        },
        preview: (state, { payload: { payload } }: PayloadAction<Template>) => {
            const original = state.preview?.original ?? {
                editorState: state.editorState,
                images: state.images,
                files: state.files,
            };
            return {
                ...state,
                ...mergePayload(original, calcPreviewDraft(payload), true),
                preview: { loading: false, original },
            };
        },
        setEditing: (
            state,
            {
                payload: { blockKey, entityKey },
            }: PayloadAction<{
                entityKey: string;
                blockKey: string;
            }>
        ) => {
            const currentContent = state.editorState.getCurrentContent();
            const block = currentContent.getBlockForKey(blockKey);
            block.findEntityRanges(
                (character) => {
                    const charEntity = character.getEntity();
                    return !!charEntity && charEntity === entityKey;
                },
                (start, end) => {
                    state.editing = {
                        blockKey,
                        entityKey,
                        data: currentContent.getEntity(entityKey).getData(),
                        start,
                        end,
                    };
                }
            );
        },
        calcTemplate: (
            state,
            { payload: { payload } }: PayloadAction<Template>
        ) => {
            const original = state.preview?.original ?? {
                editorState: state.editorState,
                images: state.images,
                files: state.files,
            };
            return {
                ...state,
                ...mergePayload(original, calcPreviewDraft(payload), true),
                preview: { loading: true, original },
            };
        },
        addTemplate: (state, { payload }: PayloadAction<Payload>) => {
            const original = state.preview?.original ?? {
                editorState: state.editorState,
                images: state.images,
                files: state.files,
            };
            return {
                ...state,
                ...mergePayload(original, payload, false),
                preview: undefined,
            };
        },
    },
});

export const saveEditing = createAsyncThunk(
    'PayloadEditor/UpdateEntityData',
    async (
        payload: {
            idEditor: string;
            idGenericEditor?: string;
            entityData: DraftEntityData;
            concepts: Concept[];
        },
        thunkAPI
    ) => {
        const state = thunkAPI.getState() as RootState;
        const entities = state.site.entities;
        const project = state.site.projects[state.site.idProject ?? ''];
        const projectEntities = project?.entities ?? [];
        const steps =
            state.genericEditor[payload.idGenericEditor ?? '']?.steps ?? {};

        const text = EntityDataMapper(payload.entityData).calcEditorText(
            state.site,
            payload.concepts,
            steps,
            state.conversationEditor.steps
        );
        return { ...payload, entities, projectEntities, text };
    }
);

export const addEntity = createAsyncThunk(
    'PayloadEditor/AddEntity',
    async (
        payload: {
            editorType: TextEditorTypes;
            autoEdit: boolean;
            isMapping?: boolean;
            idEditor: string;
            idGenericEditor?: string;
            data: DraftEntityData;
            styles: string[];
            concepts: Concept[];
        },
        thunkAPI
    ) => {
        const state = thunkAPI.getState() as RootState;
        const entities = state.site.entities;
        const project = state.site.projects[state.site.idProject ?? ''];
        const projectEntities = project?.entities ?? [];
        const steps =
            state.genericEditor[payload.idGenericEditor ?? '']?.steps ?? {};
        const text = EntityDataMapper(payload.data).calcEditorText(
            state.site,
            payload.concepts,
            steps,
            state.conversationEditor.steps
        );
        return { ...payload, text, entities, projectEntities };
    }
);

export const calcPayload = createAsyncThunk(
    'PayloadEditor/CalcPayload',
    async (
        {
            idEditor,
            required,
            allowEntities = true,
        }: { idEditor: string; required?: boolean; allowEntities?: boolean },
        thunkAPI
    ) => {
        const state = (thunkAPI.getState() as RootState).payloadEditors[
            idEditor
        ];
        if (!state) return null;
        if (required) {
            const length =
                state.editorState?.getCurrentContent()?.getPlainText('')
                    ?.length ?? 0;
            if (length === 0) return null;
        }

        const currentContent = state.editorState.getCurrentContent();
        if (!allowEntities) {
            const invalidEntities: string[] = [];
            for (const block of currentContent.getBlocksAsArray()) {
                block.findEntityRanges(
                    (range) => {
                        const entityKey = range.getEntity();
                        if (!entityKey) return false;
                        const entity = currentContent.getEntity(entityKey);
                        if (entity.getType() === DraftEntityTypes.EUREKA)
                            invalidEntities.push(entityKey);
                        return false;
                    },
                    () => {}
                );
            }
            if (invalidEntities.length > 0) {
                const action = triggerError(idEditor) as any;
                action.meta = { idEditor };
                thunkAPI.dispatch(action);
                return thunkAPI.rejectWithValue(invalidEntities);
            }
        }

        const draft = produce(
            draftRawTrim(convertToRaw(currentContent)),
            (draft) => {
                for (const block of draft.blocks) {
                    block.inlineStyleRanges = block.inlineStyleRanges.filter(
                        (style) =>
                            style.style !== (DraftStyleTypes.NESTED as any)
                    );
                }
            }
        );

        const images: Record<string, PayloadImage> = {};

        /** Remove nested Styling */

        for (const [imageKey, image] of Object.entries(state.images)) {
            if (image.state !== PayloadEditorStateTypes.COMPLETED) {
                const action = PayloadEditorActions.setDirty();
                (action as AnyAction).meta = { idEditor };
                thunkAPI.dispatch(action);
                return null;
            }
            images[imageKey] = {
                imageKey,
                S3Key: image.S3Key,
                url: image.url,
            };
        }

        const files: PayloadFile[] = [];

        for (const file of PayloadEditorFilesSelectors.selectAll(state)) {
            if (file.state !== PayloadEditorStateTypes.COMPLETED) {
                const action = PayloadEditorActions.setDirty();
                (action as AnyAction).meta = { idEditor };
                thunkAPI.dispatch(action);
                return null;
            }
            files.push({
                S3Key: file.S3Key,
                fileName: file.fileName,
            });
        }

        return {
            text: currentContent.getPlainText(),
            draft,
            images,
            files,
        };
    }
);

export const triggerError = createAsyncThunk.withTypes<{
    state: RootState;
    dispatch: AppDispatch;
    pendingMeta: {
        idEditor: string;
    };
    fulfilledMeta: {
        idEditor: string;
    };
}>()('PayloadEditor/triggerError', async (idEditor: string, thunkAPI) => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return thunkAPI.fulfillWithValue({}, { idEditor });
});

export const isFocused = createAsyncThunk(
    'PayloadEditor/isFocused',
    async (idEditor: string, thunkAPI) => {
        const state = (thunkAPI.getState() as RootState).payloadEditors[
            idEditor
        ];
        return !!state?.editorState.getSelection().getHasFocus();
    }
);

export const setupPayloadEditor = createAsyncThunk(
    'PayloadEditor/Setup',
    async (
        payload: {
            idEditor: string;
            payload?: Payload;
            templateContext?: PayloadEditorTemplateContext;
            keepIfExists?: boolean;
        },
        thunkAPI
    ) => {
        const state = thunkAPI.getState() as RootState;
        const entities = state.site.entities;
        const projectEntities =
            state.site.projects[state.site.idProject ?? '']?.entities ?? [];

        if (payload.payload?.draft) {
            let contentState = convertFromRaw(payload.payload.draft);
            for (const [entityKey, entity] of Object.entries(
                payload.payload.draft.entityMap as Record<
                    string,
                    RawDraftEntity<DraftEntityData>
                >
            )) {
                if (isMappableEntityData(entity.data)) {
                    const raw = convertToRaw(contentState);
                    for (const block of raw.blocks) {
                        const entityRange = block.entityRanges.find(
                            (range) => range.key + '' === entityKey
                        );
                        if (!entityRange) continue;
                        let start = entityRange.offset;
                        let end = entityRange.offset + entityRange.length;
                        contentState = updateBlockWithEntity(
                            contentState,
                            block.key,
                            start,
                            end,
                            calcMappableEntityData(
                                entity.data,
                                entities,
                                projectEntities
                            ),
                            entity.data
                        );
                    }
                }
            }
            payload.payload.draft = convertToRaw(contentState) as EurekaDraft;
        }
        return payload;
    }
);

export const clearPayloadEditor = createAction<string>('PayloadEditor/Clear');
export const clearPayloadEditorByStartsWith = createAction<string>(
    'PayloadEditor/ClearByStartsWith'
);

export const setDisabled = createAction(
    'PayloadEditor/SetDisabled',
    function prepare(
        idEditor: string,
        payload: boolean = false
    ): {
        meta: { idEditor: string };
        payload: boolean;
    } {
        return {
            meta: { idEditor },
            payload,
        };
    }
);

export const PayloadEditorActions = {
    ...PayloadEditorSlice.actions,
    setupPayloadEditor,
    clearPayloadEditor,
    setDisabled,
    clearPayloadEditorByStartsWith,
    calcPayload,
    isFocused,
    addEntity,
    saveEditing,
};

export function PayloadEditorReducer(
    state: Record<string, PayloadEditorState> = {},
    action: AnyAction
): Record<string, PayloadEditorState> {
    let idEditor = action.meta?.idEditor;
    if (clearPayloadEditor.match(action)) {
        return produce(state, (state) => {
            delete state[action.payload];
        });
    } else if (clearPayloadEditorByStartsWith.match(action)) {
        return produce(state, (state) => {
            for (const key of Object.keys(state)) {
                if (key.startsWith(action.payload)) delete state[key];
            }
        });
    } else if (setupPayloadEditor.fulfilled.match(action)) {
        return produce(state, (state) => {
            const { idEditor, payload, keepIfExists, templateContext } =
                action.payload;
            if (keepIfExists && state[idEditor]) {
                let found = false;
                state[idEditor].editorState
                    .getCurrentContent()
                    .getBlocksAsArray()
                    .find((block) =>
                        block.findStyleRanges(
                            (style) => style.hasStyle('NESTED'),
                            (start, end) => {
                                if (
                                    !block.getEntityAt(start) ||
                                    !block.getEntityAt(end)
                                )
                                    found = true;
                            }
                        )
                    );
                if (found) {
                    state[idEditor] = {
                        ...state[idEditor],
                        editorState: mapEditorState(payload),
                    };
                }
                return;
            } else if (payload) {
                state[idEditor] = {
                    templateContext,
                    editorState: mapEditorState(payload),
                    files: mapFiles(payload),
                    images: mapImages(payload),
                    isDirty: false,
                    hasError: false,
                };
            } else {
                state[idEditor] = {
                    ...InitialPayloadEditorState,
                    templateContext,
                };
            }
        });
    } else if (addEntity.fulfilled.match(action)) {
        const { data, styles, entities, idEditor, editorType } = action.payload;
        const isInput =
            editorType === TextEditorTypes.INPUT ||
            editorType === TextEditorTypes.ENTITYPICKER;
        const replace = editorType === TextEditorTypes.ENTITYPICKER;

        const { autoEdit, projectEntities } = action.payload;
        let text = action.payload.text;
        return {
            ...state,
            [idEditor]: produce(state[idEditor], (state) => {
                let editorState = state.editorState;
                let contentState = editorState.getCurrentContent();
                if (replace) {
                    editorState = EditorState.createEmpty();
                    contentState = editorState.getCurrentContent();
                }

                const selectionState = editorState.getSelection();
                const blockKey = selectionState.getAnchorKey();
                const offset = selectionState.getAnchorOffset();

                const isMappable = isMappableEntityData(data);
                const entityKey = contentState
                    .createEntity('EUREKA', 'IMMUTABLE', data)
                    .getLastCreatedEntityKey();
                //TODO: Usar lo que está seleccionado actualmente para ponerlo dentro del grupo
                const currentStyle = editorState.getCurrentInlineStyle();
                contentState = Modifier.insertText(
                    contentState,
                    new SelectionState({
                        anchorKey: blockKey,
                        anchorOffset: offset,
                        focusKey: blockKey,
                        focusOffset: offset,
                    }),
                    text,
                    currentStyle.isEmpty()
                        ? styles && !isMappable && !isInput
                            ? OrderedSet.of(...styles)
                            : undefined
                        : currentStyle,
                    entityKey
                );
                if (isMappable) {
                    const mappedContent = calcMappableEntityData(
                        data,
                        entities,
                        projectEntities
                    );
                    text = mappedContent.blocks[0].text;

                    //insertar el texto de verdad en el texto previo
                    contentState = updateBlockWithEntity(
                        contentState,
                        blockKey,
                        offset,
                        offset + text.length,
                        mappedContent,
                        data
                    );
                }
                editorState = EditorState.push(
                    editorState,
                    contentState,
                    'insert-characters'
                );
                let spacingOffset = 0;
                if (!isInput) {
                    contentState = Modifier.insertText(
                        contentState,
                        new SelectionState({
                            anchorKey: blockKey,
                            anchorOffset: offset + text.length,
                            focusKey: blockKey,
                            focusOffset: offset + text.length,
                        }),
                        ' ',
                        currentStyle
                    );
                    editorState = EditorState.push(
                        editorState,
                        contentState,
                        'insert-characters'
                    );
                    spacingOffset = 1;
                }
                editorState = EditorState.forceSelection(
                    editorState,
                    new SelectionState({
                        anchorKey: blockKey,
                        anchorOffset: offset + text.length + spacingOffset,
                        focusKey: blockKey,
                        focusOffset: offset + text.length + spacingOffset,
                    })
                );
                state.editorState = editorState;
                if (autoEdit)
                    state.editing = {
                        blockKey,
                        entityKey,
                        data,
                        start: offset,
                        end: offset + text.length,
                    };
            }),
        };
    } else if (saveEditing.fulfilled.match(action)) {
        const { idEditor, entityData, text, entities, projectEntities } =
            action.payload;
        return {
            ...state,
            [idEditor]: produce(state[idEditor], (state) => {
                const { editorState, editing } = state;
                if (!editing) return;
                const { blockKey, start, end } = editing;
                let contentState = state.editorState.getCurrentContent();
                if (isMappableEntityData(entityData)) {
                    const nestedBlock = calcMappableEntityData(
                        entityData,
                        entities,
                        projectEntities
                    );
                    contentState = updateBlockWithEntity(
                        contentState,
                        blockKey,
                        start,
                        end,
                        nestedBlock,
                        entityData
                    );
                } else {
                    const content = stringToDraft(text);

                    const block = contentState.getBlockForKey(blockKey);
                    const startStyles = block.getInlineStyleAt(start).toArray();
                    const endStyles = block.getInlineStyleAt(end - 1).toArray();
                    const styles = startStyles.filter(
                        (style) => endStyles.indexOf(style) !== -1
                    );
                    if (
                        styles.length > 0 &&
                        content.blocks[0].inlineStyleRanges.length === 0
                    ) {
                        for (const style of styles) {
                            content.blocks[0].inlineStyleRanges.push({
                                offset: 0,
                                length: text.length,
                                style: style as any,
                            });
                        }
                    }
                    contentState = updateBlockWithEntity(
                        contentState,
                        blockKey,
                        start,
                        end,
                        content,
                        entityData
                    );
                }

                state.editorState = EditorState.push(
                    editorState,
                    contentState,
                    'change-block-data'
                );
                state.editorState = EditorState.acceptSelection(
                    state.editorState,
                    new SelectionState({
                        anchorKey: blockKey,
                        anchorOffset: start,
                        focusKey: blockKey,
                        focusOffset: end,
                    })
                );
                state.editing = undefined;
            }),
        };
    } else if (triggerError.pending.match(action)) {
        const idEditor = action.meta.arg;
        return produce(state, (state) => {
            if (state[idEditor]) state[idEditor].hasError = true;
        });
    } else if (triggerError.fulfilled.match(action)) {
        const idEditor = action.meta.arg;
        return produce(state, (state) => {
            if (state[idEditor]) state[idEditor].hasError = false;
        });
    } else if (!idEditor) return state;
    return {
        ...state,
        [idEditor]: PayloadEditorSlice.reducer(state[idEditor], action),
    };
}

interface PayloadEditorPayload {
    editorState: EditorState;
    images: Record<string, PayloadEditorImage>;
    files: EntityState<PayloadEditorFile>;
}

function mergePayload(
    original: PayloadEditorPayload,
    payload: Payload,
    selectStart = false
): PayloadEditorPayload {
    const state: PayloadEditorPayload = {
        editorState: original.editorState,
        images: original.images,
        files: {
            entities: {},
            ids: [],
        },
    };
    payload = clonePayload(payload);
    for (const file of BaseFilesSelectors.selectAll(original.files)) {
        state.files.entities[file.id] = file;
        state.files.ids.push(file.id);
    }
    if (payload.draft) {
        const blockMap = convertFromRaw(
            invertDraftRawTrim(payload.draft)
        ).getBlockMap();
        const currentContent = state.editorState.getCurrentContent();
        const modifiedContent = Modifier.replaceWithFragment(
            currentContent,
            state.editorState.getSelection(),
            blockMap
        );
        let key = state.editorState.getSelection().getAnchorKey();
        state.editorState = EditorState.push(
            state.editorState,
            modifiedContent,
            'insert-fragment'
        );
        if (!selectStart)
            for (const block of modifiedContent.getBlocksAsArray()) {
                if (!currentContent.getBlockForKey(block.getKey()))
                    key = block.getKey();
            }
        const length = modifiedContent.getBlockForKey(key).getText().length;
        state.editorState = EditorState.forceSelection(
            state.editorState,
            new SelectionState({
                anchorKey: key,
                anchorOffset: length,
                focusKey: key,
                focusOffset: length,
            })
        );
    }
    if (payload.images)
        state.images = { ...state.images, ...mapImages(payload) };
    if (payload.files) state.files = mapFiles(payload, state.files);
    return state;
}

function mapEditorState(payload: Payload | undefined): EditorState {
    return payload?.draft
        ? EditorState.createWithContent(
              convertFromRaw(invertDraftRawTrim(payload.draft))
          )
        : EditorState.createEmpty();
}

function mapImages(
    payload: Payload | undefined
): Record<string, PayloadEditorImage> {
    const images: Record<string, PayloadEditorImage> = {};
    if (payload?.images) {
        for (const [imageKey, image] of Object.entries(payload.images)) {
            if (!image.url) console.error('Missing Image Url: ' + imageKey);
            images[imageKey] = {
                state: PayloadEditorStateTypes.COMPLETED,
                imageKey,
                S3Key: image.S3Key,
                url: image.url ?? '',
            };
        }
    }
    return images;
}

function mapFiles(
    payload: Payload | undefined,
    files = filesAdapter.getInitialState()
): EntityState<PayloadEditorFile> {
    if (payload?.files) {
        for (const file of payload.files) {
            files.entities[file.S3Key] = {
                id: file.S3Key,
                S3Key: file.S3Key,
                fileName: file.fileName,
                state: PayloadEditorStateTypes.COMPLETED,
            };
            files.ids.push(file.S3Key);
        }
    }
    return files;
}

const calcPreviewDraft = (payload: Payload): Payload => {
    return produce(payload, (payload) => {
        if (payload.draft) {
            const draft = invertDraftRawTrim(payload.draft);
            for (const block of draft.blocks) {
                if (block.type === 'atomic') {
                    block.type = 'preview-atomic';
                    continue;
                }
                block.inlineStyleRanges.push({
                    length: block.text.length,
                    offset: 0,
                    style: 'PREVIEW' as any,
                });
            }
            payload.draft = draft;
        }
    });
};

const clonePayload = (payload: Payload): Payload => {
    return produce(payload, (payload) => {
        if (payload.draft) {
            for (const block of payload.draft.blocks) {
                block.key = nanoid();
            }
        }

        if (payload.draft && payload.images) {
            for (const [imageKey, image] of Object.entries(payload.images)) {
                const newKey = nanoid();
                image.imageKey = newKey;
                payload.images[newKey] = image;
                delete payload.images[imageKey];
                for (const entity of Object.values(payload.draft.entityMap)) {
                    if (
                        entity.type === 'IMAGE' &&
                        entity.data.imageKey === imageKey
                    ) {
                        entity.data.imageKey = newKey;
                    }
                }
            }
        }
    });
};

const calcMappableEntityData = (
    data: MappableDraftEntityData,
    _entities: Record<string, Entity>,
    projectEntities: ProjectEntity[]
): EurekaDraft => {
    return {
        blocks: [
            produce(data.block, (block) => {
                let addStyles = true;
                if (
                    data.type === DraftEntityDataTypes.AGENTS &&
                    data.renderType === 'INLINE'
                ) {
                    calcInlineList(
                        data.entityMap,
                        block,
                        DraftEntityDataMappingTypes.AGENT_MAPPING,
                        data.separator ?? ', '
                    );
                } else if (
                    data.type === DraftEntityDataTypes.CONCEPT &&
                    data.renderType === 'INLINE'
                ) {
                    calcInlineList(
                        data.entityMap,
                        block,
                        DraftEntityDataMappingTypes.CONCEPT_MAPPING,
                        data.separator ?? ', '
                    );
                } else if (
                    data.type === DraftEntityDataTypes.ENTITYVALUES &&
                    data.renderType === 'INLINE'
                ) {
                    const projectEntity = projectEntities.find(
                        (entity) => entity.idEntity === data.idEntity
                    );
                    if (projectEntity?.multiple) {
                        calcInlineList(
                            data.entityMap,
                            block,
                            DraftEntityDataMappingTypes.ENTITYVALUE_MAPPING,
                            data.separator ?? ', '
                        );
                    } else addStyles = false;
                }
                if (addStyles) addNestedStyles(block);
                block.entityRanges = [
                    {
                        offset: 0,
                        length: block.text.length,
                        key: 0,
                    },
                ];
            }),
        ],
        entityMap: {
            [0]: {
                type: DraftEntityTypes.EUREKA,
                mutability: 'IMMUTABLE',
                data,
            },
        },
    };
};

const addNestedStyles = (block: RawDraftContentBlock): void => {
    for (const entity of block.entityRanges) {
        block.inlineStyleRanges.push({
            offset: entity.offset,
            length: entity.length,
            style: DraftStyleTypes.NESTED as any,
        });
    }
};

const updateBlockWithEntity = (
    contentState: ContentState,
    blockKey: string,
    start: number,
    end: number,
    content: EurekaDraft,
    entityData: DraftEntityData
): ContentState => {
    const blockMap = convertFromRaw(content).getBlockMap();

    contentState = Modifier.replaceWithFragment(
        contentState,
        new SelectionState({
            anchorKey: blockKey,
            anchorOffset: start,
            focusKey: blockKey,
            focusOffset: end,
        }),
        blockMap
    );
    end = start + content.blocks[0].text.length;
    contentState = contentState.createEntity('EUREKA', 'IMMUTABLE', entityData);
    contentState = Modifier.applyEntity(
        contentState,
        new SelectionState({
            anchorKey: blockKey,
            anchorOffset: start,
            focusKey: blockKey,
            focusOffset: end,
        }),
        contentState.getLastCreatedEntityKey()
    );
    return contentState;
};

const calcInlineList = (
    entityMap: Record<string, RawDraftEntity>,
    block: RawDraftContentBlock,
    type: DraftEntityDataMappingTypes,
    separator: string = ', '
): void => {
    let text = '';
    const styles = [];
    let offset = 0;
    for (let i = 1; i <= 2; i++) {
        const subBlock = produce(
            JSON.parse(JSON.stringify(block)) as RawDraftContentBlock,
            (block) => {
                const entityRanges = block.entityRanges.sort(
                    (r1, r2) => r2.offset - r1.offset
                );
                for (const entityRange of entityRanges) {
                    const { data } = entityMap[entityRange.key];
                    if (data.type === type) {
                        const pos = entityRange.offset + entityRange.length;
                        block.text =
                            block.text.slice(0, pos) +
                            ' ' +
                            i +
                            block.text.slice(pos);
                        for (const style of block.inlineStyleRanges) {
                            if (style.offset > pos) style.offset += 2;
                            else if (
                                style.offset < pos &&
                                style.offset + style.length >= pos
                            )
                                style.length += 2;
                        }
                    }
                }
                for (const style of block.inlineStyleRanges) {
                    style.offset += offset;
                }
            }
        );
        text += subBlock.text + separator;
        styles.push(...subBlock.inlineStyleRanges);
        offset += subBlock.text.length + separator.length;
    }
    block.inlineStyleRanges = styles;
    if (separator[separator.length - 1] === ' ') {
        text.slice(0, -1);
    }
    text = text.trim() + '...';
    block.text = text;
    block.inlineStyleRanges = styles;
};
