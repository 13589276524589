import React, { useState, useEffect, useRef } from 'react';
import styles from './TicketListItem.module.css';
import ClassifierPill from '../../../shared/ClassifierPill/ClassifierPill';
import { Ticket } from '../../../@Types/@Types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import StatePill from '../../../shared/StatePill/StatePill';
import { intervalToDuration } from 'date-fns';
import ValuePill from '../../../shared/ValuePill/ValuePill';
interface TicketListItemProps {
    /** The selected Ticket */
    selectedElement?: Ticket;
    /** The ticket to display */
    element?: Ticket;
    /** Function to call when element is clicked */
    handleItemClick?: Function;
    /** Today's date */
    today: Date;
    showSidebar?: boolean;
    /** If the list should show notifications */
    showNotifications?: boolean;
    paddingTop?: number;
    paddingBottom?: number;
}

function TicketListItem({
    selectedElement,
    element,
    handleItemClick,
    today,
    paddingTop = 10,
    showSidebar = true,
    showNotifications,
    paddingBottom = 15,
}: TicketListItemProps): JSX.Element {
    if (!element) {
        return <div></div>;
    }
    const [overFlowingItems, setOverFlowingItems] = useState(0);
    const siteInfo = useSelector((state: RootState) => state.site);
    const project = useSelector(
        (state: RootState) => state.site.projects[element.idProject]
    );
    const [loaded, setLoaded] = useState(false);
    const pillsRef = useRef<any[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);
    const pillsContainerRef = useRef<HTMLDivElement>(null);
    // here we handle what happens when user scrolls to the loading element
    // in this case we just update page variable
    const handleObserver = (entities: any): void => {
        for (const entity of entities) {
            if (entity.intersectionRatio === 0) {
                try {
                    entity.target.style.display = 'none';
                } catch (e) {
                    //
                }
            }
        }

        let count = 0;
        for (const ref of pillsRef.current) {
            if (ref?.style?.display === 'none') {
                count++;
            }
        }
        setOverFlowingItems(count);
    };

    useEffect(() => {
        if (selectedElement?._id === element._id && containerRef.current) {
            containerRef.current.scrollIntoView(true);
        }
        setLoaded(true);
        const options = {
            root: pillsContainerRef.current,
            rootMargin: '0px',
            threshold: 1.0,
        };
        if ('IntersectionObserver' in window) {
            const observer = new IntersectionObserver(handleObserver, options);
            for (const ref of pillsRef.current) {
                observer.observe(ref);
            }
            return (): void => observer.disconnect();
        }
        return (): void => {};
    }, []);

    const calcClientTitle = (): string => {
        let name: string | undefined = element?.client?.name ?? '';
        if (element?.client?.lastName) {
            if (name) {
                name += ' ';
            }
            name += element?.client?.lastName;
        }
        if (!name) {
            name = element?.client?.email;
        }
        if (!name) {
            name = element?.client?.cel;
        }
        if (!name) {
            name = element?.client?.identifier;
        }
        if (name) {
            return name;
        } else {
            return 'Anónimo';
        }
    };
    const calcEntityTitle = (): JSX.Element => {
        const entity = siteInfo.entities[project?.idPrimaryEntity ?? ''];
        if (entity?._id === siteInfo.organization?.idCompanyEntity) {
            const company = element?.company;
            if (company && company.values.name) {
                return (
                    <React.Fragment>
                        <span> - </span>
                        <span className={styles.entity}>
                            {company.values.name}
                        </span>
                    </React.Fragment>
                );
            }
        } else if (entity) {
            const entityValues = element?.entities?.[entity._id];
            const label = entityValues
                ?.map((e) => e.values?.[entity.idLabelStep])
                .join(', ');
            if (label) {
                return (
                    <React.Fragment>
                        <span> - </span>
                        <span className={styles.entity}>{label}</span>
                    </React.Fragment>
                );
            }
        }
        return <div></div>;
    };

    const elements = [];
    if (siteInfo.classifiers && element) {
        //Iterate the roots in order
        for (const root of siteInfo.classifierRoots) {
            //If a clasifier is of the same root add it to the list.
            for (const clasifier of element.classifiers) {
                if (clasifier.idRoot === root) {
                    elements.push(clasifier);
                }
            }
        }
    }

    return (
        <div
            className={styles.emptyContainer}
            ref={containerRef}
            style={{
                visibility: loaded ? 'visible' : 'hidden',
                paddingTop,
                paddingBottom,
            }}
        >
            {showNotifications &&
                element.notifications &&
                Object.keys(element.notifications).length > 0 && (
                    <div className={styles.notificationIcon}>
                        {Object.keys(element.notifications).length}
                    </div>
                )}
            <div
                className={
                    styles.container +
                    (selectedElement?._id === element._id
                        ? ' selected-item-shadow'
                        : '')
                }
                onClick={(): void => handleItemClick?.(element, true)}
            >
                {showSidebar && <div className={styles.sideBar}></div>}
                <div
                    className={styles.infoContainer}
                    style={{
                        width: showSidebar ? 'calc(100% - 10px)' : '100%',
                    }}
                >
                    <div className={styles.ticketInfo}>
                        <label className={styles.clientNameLbl}>
                            {calcClientTitle()}
                            {calcEntityTitle()}
                        </label>
                        <label
                            className={styles.ticketTitleLbl}
                            title={
                                element.subject
                                    ? element.subject
                                    : element.caseNumber
                            }
                        >
                            {element.subject
                                ? element.subject
                                : element.caseNumber}
                        </label>
                        <div
                            ref={pillsContainerRef}
                            className={styles.classifiersContainer + ' flexbox'}
                        >
                            {element.classifiers?.length === 0 && (
                                <div
                                    key={'STATE'}
                                    className={styles.pillContainer}
                                >
                                    <StatePill
                                        state={element.state}
                                        idState={undefined}
                                        hideLabel
                                    />
                                </div>
                            )}
                            {elements.map((clasifier, index) => {
                                if (index === 0) {
                                    return (
                                        <div
                                            className={
                                                styles.firstLineContainer
                                            }
                                            key={index}
                                        >
                                            <StatePill
                                                state={element.state}
                                                idState={undefined}
                                                hideLabel
                                            />
                                            <div
                                                className={
                                                    styles.firstPillContainer
                                                }
                                                ref={(el): any =>
                                                    (pillsRef.current[index] =
                                                        el)
                                                }
                                            >
                                                <ClassifierPill
                                                    cursor="pointer"
                                                    idRoot={clasifier.idRoot}
                                                    idValue={clasifier.idValue}
                                                />
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            key={index}
                                            className={styles.pillContainer}
                                            ref={(el): any =>
                                                (pillsRef.current[index] = el)
                                            }
                                        >
                                            <ClassifierPill
                                                cursor="pointer"
                                                idRoot={clasifier.idRoot}
                                                idValue={clasifier.idValue}
                                            />
                                        </div>
                                    );
                                }
                            })}
                            {project.values?.order
                                .filter(
                                    (value) =>
                                        project.values.values[value].color &&
                                        element.values?.[value]
                                )
                                .map((value, index) => (
                                    <div
                                        key={index}
                                        className={styles.pillContainer}
                                        ref={(el): any =>
                                            (pillsRef.current[
                                                elements.length + index
                                            ] = el)
                                        }
                                    >
                                        <ValuePill
                                            cursor="pointer"
                                            ticketValue={element.values![value]}
                                            projectValue={
                                                project.values.values[value]
                                            }
                                        />
                                    </div>
                                ))}
                            {overFlowingItems !== 0 && (
                                <div className={styles.notShowingIndicatorCont}>
                                    <div className={styles.notShowingIndicator}>
                                        <label
                                            className={
                                                styles.notShowingIndicatorLbl
                                            }
                                        >
                                            +{overFlowingItems}
                                        </label>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={styles.bottomPadding}></div>
                    </div>

                    <div
                        className={styles.dateContainer}
                        title={
                            element?.resolution_date?.toLocaleDateString() +
                            ' ' +
                            element?.resolution_date?.toLocaleTimeString()
                        }
                    >
                        <label className={styles.ticketCaseLbl}>
                            {element.caseNumber}
                        </label>
                        {element.state?.type === 'CLOSED' ? (
                            <label className={styles.closedLbl}>
                                {'Cerrado'}
                            </label>
                        ) : (
                            calcDateLbl(today, element.resolution_date)
                        )}
                        {element.path && (
                            <img
                                alt={'SubCaso'}
                                title="SubCaso"
                                className={styles.subTicketImg}
                                src={'/media/icons/subticket.svg'}
                            ></img>
                        )}
                        {element.subTickets &&
                            element.subTickets?.length > 0 && (
                                <img
                                    alt={'SubCaso'}
                                    title="SubCaso"
                                    className={styles.subTicketImg}
                                    src={'/media/icons/parent.svg'}
                                ></img>
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default TicketListItem;

const calcDateLbl = (today: Date, resolution_date: Date): JSX.Element => {
    const expired = resolution_date.getTime() < today.getTime();
    let duration;
    if (expired) {
        duration = intervalToDuration({
            start: resolution_date,
            end: today,
        });
    } else {
        duration = intervalToDuration({
            start: today,
            end: resolution_date,
        });
    }

    return (
        <React.Fragment>
            <label className={styles.dateTitleLbl}>
                {expired ? 'Venció hace' : 'Vence en'}
            </label>
            <label className={expired ? styles.dateLblExpired : styles.dateLbl}>
                {calcDurationLbl(duration)}
            </label>
        </React.Fragment>
    );
};

export function calcDurationLbl(duration: Duration): string {
    if (duration.years ?? 0 > 0) {
        return duration.years + (duration.years == 1 ? ' año' : ' años');
    } else if (duration.months ?? 0 > 0) {
        return duration.months + (duration.months === 1 ? ' mes' : ' meses');
    } else if (duration.days ?? 0 > 0) {
        return duration.days + (duration.days === 1 ? ' día' : ' días');
    } else if (duration.hours ?? 0 > 0) {
        return duration.hours + (duration.hours === 1 ? ' hora' : ' horas');
    } else {
        return 'menos de 1 hora';
    }
}
