import styles from './AIDialog.module.css';
import { AIData } from '../../../../@Types/FlowTypes/NodeTypes/AINode';
import Toggle from '../../../../shared/Toggle/Toggle';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../utils/_store';
import { checkAdmin } from '../../../../utils/PermissionsFunctions';

interface AIDialogProps {
    /** The data of the transformation to display */
    data: AIData & {
        /** Function used to open the dialog  */
        openDialog: Function;
    };
    /** Function called when a value needs to update */
    handleUpdate: Function;
}

function AIDialog({ data, handleUpdate }: AIDialogProps): JSX.Element {
    const user = useSelector((state: RootState) => state.site.user);
    if (!data) {
        return <div></div>;
    }
    return (
        <div className={styles.container}>
            <div className={styles.scrollContainer}>
                <div className={styles.content}>
                    <div className={styles.toggleLabelContainer}>
                        Clasificación Automática:
                        <div className={styles.toggleContainer}>
                            <Toggle
                                checked={data.classify === true}
                                onChange={(classify: boolean): void => {
                                    handleUpdate({
                                        ...data,
                                        classify,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <p className={styles.helperText}>
                        Se le harán sugerencias de clasificaciónes a los agentes
                    </p>
                    <div className={styles.toggleLabelContainer}>
                        Sugerir Respuesta:
                        <div className={styles.toggleContainer}>
                            <Toggle
                                checked={data.suggestReply === true}
                                onChange={(suggestReply: boolean): void => {
                                    handleUpdate({
                                        ...data,
                                        suggestReply,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <p className={styles.helperText}>
                        Se le harán sugerencias de respuestas a los agentes
                    </p>
                    {checkAdmin(user) && data.suggestReply && (
                        <div className={styles.autoSendContainer}>
                            <div className={styles.toggleLabelContainer}>
                                Enviar Respuesta Sugerida:
                                <div className={styles.toggleContainer}>
                                    <Toggle
                                        checked={data.autoSend === true}
                                        onChange={(autoSend: boolean): void => {
                                            handleUpdate({
                                                ...data,
                                                autoSend,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AIDialog;
