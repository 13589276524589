import React, { useEffect } from 'react';
import styles from './ProjectPermissions.module.css';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import Typography from '@material-ui/core/Typography';
import {
    ProjectPermissions,
    ProjectPermissionsList,
} from '../../../../constants/Permissions';
import { Project } from '../../../../@Types/@Types';
import { Agent, PartialRecord } from '../../../../@Types/Agent';
import Toggle from '../../../../shared/Toggle/Toggle';
import RoundedAccordion from '../../../../shared/RoundedAccordion/RoundedAccordion';
import RoundedButton from '../../../../shared/RoundedButton/RoundedButton';

interface ProjectPermissionsProps {
    /** The selected Agent */
    selectedElement?: Agent;
    project: Project;
    permissions: PartialRecord<ProjectPermissions, boolean>;
    handleChange: (
        permissions: PartialRecord<ProjectPermissions, boolean>
    ) => void;
}

export default function ProjectPermissionsComponent({
    project,
    permissions,
    handleChange,
    selectedElement,
}: ProjectPermissionsProps): JSX.Element {
    const [expanded, setExpanded] = React.useState(false);

    useEffect(() => {
        setExpanded(false);
    }, [selectedElement?._id]);

    const active = ProjectPermissionsList.filter(
        (permission) => permissions[permission.key] === true
    );
    const all = active.length === ProjectPermissionsList.length;

    return (
        <RoundedAccordion
            width="400px"
            backgroundColor="var(--primary2)"
            expanded={expanded}
            onChange={(): void => setExpanded(!expanded)}
        >
            <AccordionSummary expandIcon={<ExpandMoreRoundedIcon />}>
                <div className={styles.summaryContainer}>
                    <Typography>{project.name}</Typography>
                    <div className={styles.countContainer}>
                        {active.length > 0 && (
                            <div className={styles.countLbl}>
                                {active.length}
                            </div>
                        )}
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className={styles.projPermissionsList}>
                    {ProjectPermissionsList.map((permission, index) => (
                        <div
                            className={styles.projPermission + ' flexbox'}
                            key={index}
                        >
                            <div
                                className={
                                    styles.projPermissionLbl + ' noselect'
                                }
                            >
                                {permission.label}
                            </div>
                            <div className={styles.toggleContainer}>
                                <Toggle
                                    size="small"
                                    checked={
                                        permissions[permission.key] === true
                                    }
                                    onChange={(checked: boolean): void => {
                                        handleChange({
                                            ...permissions,
                                            [permission.key]: checked,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                    <div className={styles.allBtn}>
                        <RoundedButton
                            padding="4px 10px"
                            text={all ? 'Desactivar Todos' : 'Activar Todos'}
                            backgroundColor={
                                all ? 'var(--greyBtn)' : 'var(--secondary2)'
                            }
                            color={'var(--accent)'}
                            onClick={(): void => {
                                const perms: any = {};
                                for (const perm of ProjectPermissionsList) {
                                    perms[perm.key] = !all;
                                }
                                handleChange(perms);
                            }}
                        />
                    </div>
                </div>
            </AccordionDetails>
        </RoundedAccordion>
    );
}

const AccordionSummary = withStyles({
    root: {
        minHeight: 30,
        '&$expanded': {
            minHeight: 30,
        },
        '& .Erk-MuiAccordionSummary-content': {
            margin: '6px 0px',
        },
        '& .Erk-MuiIconButton-root': {
            color: 'var(--greyFont)',
            padding: 10,
        },
    },
    content: {
        '&$expanded': {
            margin: '6px 0px',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
    root: {
        paddingTop: 0,
    },
}))(MuiAccordionDetails);
