import { Link, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import Profile from './Profile/Profile';
import Projects from './Projects/Projects';
import Organization from './Organization/Organization';
import styles from './Navbar.module.css';
import { RootState } from '../../utils/_store';
import { useSelector } from 'react-redux';
import { OrganizationPermissions } from '../../constants/Permissions';
import { checkOrgPerms } from '../../utils/PermissionsFunctions';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { Drawer, MenuItem } from '@material-ui/core';
import Support, { SupportForm } from './Support/Support';
import NotificationsBell from './Notifications/NotificationsBell/NotificationsBell';
import NotificationsMobileOption from './Notifications/NotificationsMobileOption/NotificationsMobileOption';
import { IconButton } from '@material-ui/core';
import TutorialsIcon from '../../Icons/TutorialsIcon';
interface NavbarProps {
    mobile: boolean;
}
function Navbar({ mobile }: NavbarProps): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);
    const [showSupport, setShowSupport] = useState(false);
    const user = useSelector((state: RootState) => state.site.user);
    const organization = useSelector(
        (state: RootState) => state.site.organization
    );
    const numNotifications = useSelector(
        (state: RootState) => Object.keys(state.site.notifications.map).length
    );
    const location = useLocation();
    return (
        <nav
            className={styles.navBarContainer + ' flexbox'}
            data-testid={'navbar'}
        >
            {mobile && <div className={styles.mobileSidebarIconPadding}></div>}
            <Link to="/" className="app-link">
                <div className={styles.navBarLogo}>
                    <img
                        alt={'Capta'}
                        className={styles.captaLogo}
                        src={'/Capta.svg'}
                    ></img>
                </div>
            </Link>
            {!mobile && (
                <React.Fragment>
                    <Projects mobile={mobile} />
                    {checkOrgPerms(user, organization) && (
                        <Organization mobile={mobile} />
                    )}

                    <div className={styles.rightButtonsContainer}>
                        {user?.permissions.support === true && <Support />}
                        {location.pathname.includes('/tickets') && (
                            <IconButton
                                title="Tutoriales"
                                style={{
                                    width: 50,
                                    height: 50,
                                    padding: 0,
                                    fontSize: 35,
                                    color: 'var(--accent)',
                                }}
                                id="CaptaTutorialsButton"
                            >
                                <TutorialsIcon
                                    fill={'var(--accent)'}
                                    style={{
                                        height: 40,
                                        width: 40,
                                    }}
                                />
                            </IconButton>
                        )}
                        <NotificationsBell />
                        <Profile mobile={mobile} />
                    </div>
                </React.Fragment>
            )}

            {mobile && (
                <div
                    className={styles.menu}
                    onClick={(): void => {
                        setOpen(true);
                    }}
                >
                    <MenuRoundedIcon fontSize="inherit" />
                    {numNotifications > 0 && (
                        <div className={styles.notificationIcon}>
                            {numNotifications > 99 ? '99+' : numNotifications}
                        </div>
                    )}
                </div>
            )}

            <Drawer
                anchor="top"
                open={open && mobile}
                PaperProps={{ style: { overflowX: 'hidden' } }}
                onClose={(): void => {
                    setOpen(false);
                }}
            >
                <div className={styles.menuContainer}>
                    <Projects
                        mobile={mobile}
                        handleClose={(): void => {
                            setOpen(false);
                        }}
                    />
                    {checkOrgPerms(user, organization, [
                        OrganizationPermissions.AGENTS,
                        OrganizationPermissions.COMPANIES,
                    ]) && (
                        <Organization
                            mobile={mobile}
                            handleClose={(): void => {
                                setOpen(false);
                            }}
                        />
                    )}
                    {user?.permissions.support === true && (
                        <MenuItem
                            onClick={(): void => {
                                setShowSupport(true);
                                setOpen(false);
                            }}
                        >
                            Soporte
                        </MenuItem>
                    )}
                    <Profile
                        mobile={mobile}
                        handleClose={(): void => {
                            setOpen(false);
                        }}
                    />
                    <NotificationsMobileOption
                        numNotifications={numNotifications}
                        handleClose={(): void => {
                            setOpen(false);
                        }}
                    />
                </div>
            </Drawer>
            {mobile && showSupport && (
                <SupportForm handleClose={(): void => setShowSupport(false)} />
            )}
        </nav>
    );
}
export default Navbar;
