import React, { JSX } from 'react';
import styles from './ActionNotification.module.css';
import { GroupedNotification } from './GroupedNotification/GroupedNotification';
import { UngroupedNotification } from './UngroupedNotification/UngroupedNotification';
import { Link } from 'react-router-dom';
import { MenuGroupedNotification } from '../../../../../../@Types/GroupedMenuNotification';
import { calcDate } from '../../../../../../utils/DateFunctions';
import TicketListItem from '../../../../../../pages/Tickets/TicketListItem/TicketListItem';
import { IconButton } from '@material-ui/core';
import InfoIcon from '../../../../../../Icons/InfoIcon';

export interface ActionNotificationProps {
    element: MenuGroupedNotification;
    forToast?: boolean;
    openDropdown: boolean;
    setOpenDropdown: Function;
}

export default function ActionNotification({
    element,
    forToast,
    openDropdown,
    setOpenDropdown,
}: ActionNotificationProps): JSX.Element {
    const [openInfo, openInfoSet] = React.useState<boolean>(false);

    function titleRender(): JSX.Element {
        return (
            <div className={styles.actionTitleContainerEndUser}>
                <div
                    className={styles.actionTitle}
                    style={{ fontWeight: 'bold' }}
                >
                    <div>
                        {element?.ticket ? element.ticket.caseNumber : ''}
                    </div>
                    {!forToast && (
                        <IconButton
                            onClick={(): void => openInfoSet(!openInfo)}
                            style={{
                                padding: '0px',
                                marginLeft: '4px',
                                marginBottom: '1px',
                            }}
                            className={styles.iconInfo}
                        >
                            <InfoIcon />
                        </IconButton>
                    )}
                </div>
                <div className={styles.actionProject}>
                    {element?.ticket.projectName
                        ? element.ticket.projectName
                        : ''}
                </div>
                <label
                    className={styles.actionDate}
                    style={{ textAlign: 'left' }}
                >
                    {calcDate(new Date(element.last), true)}
                </label>
            </div>
        );
    }

    return (
        <div className={styles.actionEndUserContainer}>
            {!forToast ? (
                titleRender()
            ) : (
                <Link
                    to={`/${element.ticket.idProject}/tickets/${element.ticket._id}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                >
                    {titleRender()}
                </Link>
            )}
            <div className={styles.actionContentEndUserContainer}>
                {openInfo && !forToast ? (
                    <Link
                        to={`/${element.ticket.idProject}/tickets/${element.ticket._id}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                        <div className={styles.infoTicketContainer}>
                            <TicketListItem
                                today={new Date()}
                                paddingTop={0}
                                paddingBottom={2}
                                showSidebar={false}
                                element={element.ticket}
                            />
                        </div>
                    </Link>
                ) : (
                    <>
                        {element.subs.length === 1 ? (
                            <UngroupedNotification
                                ticket={element.ticket}
                                notification={element.subs[0]}
                            />
                        ) : (
                            <GroupedNotification
                                notification={element}
                                openDropdown={openDropdown}
                                setOpenDropdown={setOpenDropdown}
                            />
                        )}
                    </>
                )}
            </div>
            {!openDropdown && element.ticket.state.type === 'CLOSED' && (
                <div className={styles.closedBadge}>Cerrado</div>
            )}
        </div>
    );
}
