import ActionTypes from '../../../../constants/ActionTypes';
import Types, { Interactions } from '../../../../constants/ActionTypes';
import styles from './Action.module.css';
import AgentsActionComponent from './Actions/Agents/Agents';
import AssignArea from './Actions/Area/Area';
import PayloadAction from '../../../../shared/RoundedPayload/PayloadComponent';
import RatingAction from './Actions/Rating/Rating';
import AutoScaleAction from './Actions/AutoScale/Autoscale';
import ActionAvatar from './ActionAvatar/ActionAvatar';
import FlowIcon from './FlowIcon/FlowIcon';
import TransferAgent from './Actions/TransferAgent/TransferAgent';
import Classification from './Actions/Classification/Classification';
import React from 'react';
import {
    Action,
    Agent,
    Entity,
    Project,
    Ticket,
    User,
} from '../../../../@Types/@Types';
import TransferArea from './Actions/TransferArea/TransferArea';
import SetCompany from './Actions/SetCompany/SetCompany';
import ChangeResolutionDate from './Actions/ChangeResolutionDate/ChangeResolutionDate';
import StateChange from './Actions/StateChange/StateChange';
import CBRPosVentaAction from './Actions/CBRPosVenta/CBRPosVenta';
import ErrorAction from './Actions/Error/ErrorAction';
import ActionBottom from './Actions/Reply/ActionBottom/ActionBottom';
import EditComment from './Actions/Comment/EditComment/EditComment';
import CommentHistory from './Actions/Comment/CommentHistory/CommentHistory';
import { calcDate } from '../../../../utils/DateFunctions';
import { RootState } from '../../../../utils/_store';
import { useSelector } from 'react-redux';
import SetEntities from './Actions/SetEntities/SetEntities';
import { Process } from '../../../../@Types/ProcessTypes/Process';
import { ActivityTypes } from '../../../../constants/ActivityTypes/ActivityTypes';
import FormActivityAction from './Actions/Activities/FormActivityAction/FormActivityAction';
import DateActivityAction from './Actions/Activities/DateActivityAction/DateActivityAction';
import ProcessChange from './Actions/ProcessChange/ProcessChange';
import CreateTicket from './Actions/CreateTicket/CreateTicket';
import IntegrationSuccessAction from './Actions/Activities/IntegrationActivityAction/IntegrationSuccessAction/IntegrationSuccesAction';
import ViewFormActivityAction from './Actions/Activities/ViewFormActivityAction/ViewFormActivityAction';
import SubjectChange from './Actions/SubjectChange/SubjectChange';
import { useCurrentProject } from '../../../../hooks';

interface ActionComponentProps {
    /** Action to display */
    element: Action;
    /** Ticket father */
    ticket: Ticket;
    /** The current user */
    user: User;
    /** If the layout is currently xsm */
    xsm?: boolean;
}

export interface ActionInfo {
    /** If the current user sent the action */
    sentByCurrentAgent: boolean | undefined;
    /** The title of the action */
    title: string | JSX.Element;
    /** The type of the action */
    type: Action['type'];
    /** The visual component of the action (under the title) */
    content?: JSX.Element;
    /** The icon to display */
    icon?: JSX.Element;
    /** The visual component under the action */
    bottom?: JSX.Element;
    /** The button component to the side of the action */
    button?: JSX.Element;
}

/**
 * Component that displays an action given its info
 * @returns Action Element
 */

function ActionComponent({
    element,
    ticket,
    user,
    xsm,
}: ActionComponentProps): JSX.Element {
    /** Calc the Action's information */
    const project = useCurrentProject();
    const entities = useSelector((state: RootState) => state.site.entities);
    const processes = useSelector(
        (state: RootState) => state.ticketsPage.processes
    );
    const actionInfo = calcInfo(
        element,
        user,
        xsm,
        ticket,
        project,
        entities,
        processes
    );
    if (actionInfo !== null)
        if (
            actionInfo.type !== ActionTypes.CLIENT_REPLY &&
            actionInfo.type !== ActionTypes.CLIENT_RATING &&
            !(
                element.type === ActionTypes.ACTIVITY &&
                element.activityType === ActivityTypes.CLIENT_FORM
            )
        ) {
            return (
                <div
                    className={styles.container}
                    style={{
                        flexDirection: actionInfo.sentByCurrentAgent
                            ? 'row-reverse'
                            : 'row',
                    }}
                    id={'action-' + element._id}
                >
                    <div className={styles.avatarContainer}>
                        {actionInfo.icon}
                    </div>

                    <div className={styles.actionContainer}>
                        {Interactions.includes(actionInfo.type) && (
                            <img
                                alt={'Interacion'}
                                style={
                                    actionInfo.sentByCurrentAgent
                                        ? {
                                              right: -5,
                                          }
                                        : {
                                              left: -5,
                                              WebkitTransform: 'scaleX(-1)',
                                              transform: 'scaleX(-1)',
                                          }
                                }
                                title={'Interacción'}
                                className={styles.interactionImg}
                                src={
                                    actionInfo.sentByCurrentAgent
                                        ? '/media/icons/chat.svg'
                                        : '/media/icons/bluechat.svg'
                                }
                            />
                        )}
                        <div
                            style={{
                                borderRadius:
                                    actionInfo.content !== undefined
                                        ? '10px 10px 0 0'
                                        : '10px 10px 10px 10px',
                            }}
                            className={
                                actionInfo.sentByCurrentAgent
                                    ? styles.actionTitleContainerUser
                                    : styles.actionTitleContainer
                            }
                        >
                            <div className={styles.actionTitle}>
                                {actionInfo.title}
                            </div>
                            <label className={styles.actionDate}>
                                {calcDate(element.creation_date, xsm)}
                            </label>
                        </div>
                        {actionInfo.content !== undefined && (
                            <div className={styles.actionContentContainer}>
                                {(element as any).isSubTicket &&
                                    (element as any).subject !== undefined && (
                                        <div className={styles.subticketLbl}>
                                            <label
                                                className={
                                                    styles.subticketTitle
                                                }
                                            >
                                                SubCaso:
                                            </label>
                                            {(element as any).subject}
                                        </div>
                                    )}
                                {actionInfo.content}
                            </div>
                        )}
                        {actionInfo.bottom !== undefined && actionInfo.bottom}
                    </div>
                    {actionInfo.button !== undefined && actionInfo.button}
                </div>
            );
        } else {
            return (
                <div className={styles.container} id={'action-' + element._id}>
                    <div className={styles.actionEndUserContainer}>
                        <div className={styles.actionTitleContainerEndUser}>
                            <div
                                className={styles.actionTitle}
                                style={{ fontWeight: 'bold' }}
                            >
                                {actionInfo.title}
                            </div>
                            <label className={styles.actionDate}>
                                {calcDate(element.creation_date, xsm)}
                            </label>
                        </div>
                        <div className={styles.actionContentEndUserContainer}>
                            {actionInfo.content}
                        </div>
                    </div>
                </div>
            );
        }
    else {
        return <div></div>;
    }
}
export default ActionComponent;

/**
 * Function that returns the Action's info given its type
 * @param element The action to calculate
 * @param user The current User
 * @param xsm
 * @param ticket
 * @returns An ActionInfo element
 */
function calcInfo(
    element: Action,
    user: User,
    xsm: boolean | undefined,
    ticket: Ticket,
    project: Project,
    entities: Record<string, Entity>,
    processes: Record<string, Process>
): ActionInfo | null {
    const action: ActionInfo = {
        sentByCurrentAgent:
            user._id === (element as { agent: Agent })?.agent?._id,
        type: element.type,
        title: '',
    };
    switch (element.type) {
        case Types.CREATE: {
            action.title = 'Caso Creado';
            if (element.idState) {
                action.content = (
                    <StateChange states={[{ idValue: element.idState }]} />
                );
            }
            return action;
        }
        case Types.COMMENT: {
            action.title = (
                <React.Fragment>
                    <span className={styles.boldName}>
                        {element.agent.name}
                    </span>
                    Comentó
                </React.Fragment>
            );
            action.content = (
                <React.Fragment>
                    <PayloadAction content={element.payload} />
                    {element.history && (
                        <CommentHistory
                            current={element}
                            userName={element.agent.name}
                        />
                    )}
                </React.Fragment>
            );
            action.icon = <ActionAvatar agent={element.agent} />;
            if (
                !xsm &&
                action.sentByCurrentAgent &&
                ticket.state.type !== 'CLOSED'
            ) {
                action.button = (
                    <div
                        className={styles.editButton}
                        data-testid={'editButton'}
                    >
                        <EditComment comment={element} />
                    </div>
                );
            }
            return action;
        }
        case Types.AUTO_COMMENT: {
            action.title = (
                <React.Fragment>Comentario Automático</React.Fragment>
            );
            action.content = (
                <React.Fragment>
                    <PayloadAction content={element.payload} />
                </React.Fragment>
            );
            action.icon = <FlowIcon />;
            return action;
        }
        case Types.CLIENT_REPLY: {
            action.title = 'Mensaje Emisor';
            action.content = <PayloadAction content={element.payload} />;
            return action;
        }
        case Types.CLIENT_RATING: {
            action.title = 'Caso Calificado';
            if (element.rating.surveyValues !== undefined) {
                action.content = (
                    <RatingAction rating={element.rating} name={element._id} />
                );
            }
            return action;
        }
        case Types.ASSIGN_AGENTS: {
            action.title =
                element.assignees.length > 1
                    ? 'Agentes Agregados'
                    : 'Agente Agregado';
            action.content = (
                <AgentsActionComponent
                    agents={element.assignees}
                    removed={false}
                />
            );
            action.icon = <ActionAvatar agent={element.agent} />;
            return action;
        }
        case Types.ASSIGN_AREA: {
            action.title = 'Area Asignada';
            action.content = <AssignArea area={element.area} />;
            action.icon = <ActionAvatar agent={element.agent} />;
            return action;
        }
        case Types.AUTO_ASSIGN_AREA: {
            action.title = 'Area Asignada';
            action.content = <AssignArea area={element.area} />;
            action.icon = <FlowIcon flow={element.flow} />;
            return action;
        }

        case Types.AUTOSCALE: {
            action.title = 'Caso Autoescalado';
            action.content = <AutoScaleAction />;
            action.icon = <ActionAvatar agent={element.agent} />;
            return action;
        }
        case Types.AUTO_AUTOSCALE:
            action.title = 'Caso Autoescalado';
            action.content = <AutoScaleAction />;
            action.icon = <FlowIcon flow={element.flow} />;
            return action;
        case Types.REMOVE_AGENT: {
            action.title =
                element.removees.length > 1
                    ? 'Agentes Removidos'
                    : 'Agente Removido';
            action.content = (
                <AgentsActionComponent
                    agents={element.removees}
                    removed={true}
                />
            );
            action.icon = <ActionAvatar agent={element.agent} />;
            return action;
        }
        case Types.AUTO_ASSIGN_AGENTS: {
            action.title =
                element.assignees.length > 1
                    ? 'Agentes Asignados'
                    : 'Agente Asignado';
            action.content = (
                <AgentsActionComponent
                    agents={element.assignees}
                    removed={false}
                />
            );
            action.icon = <FlowIcon flow={element.flow} />;
            return action;
        }
        case Types.TRANSFER_AGENT: {
            action.title = 'Caso Transferido';
            action.content = (
                <TransferAgent
                    agentsRemoved={element.agentsRemoved}
                    transferee={element.transferee}
                />
            );
            action.icon = <ActionAvatar agent={element.agent} />;
            return action;
        }
        case Types.TRANSFER_AREA: {
            action.title = 'Caso Transferido';
            action.content = (
                <TransferArea
                    agentsRemoved={element.agentsRemoved}
                    area={element.area}
                />
            );
            action.icon = <ActionAvatar agent={element.agent} />;
            return action;
        }
        case Types.AUTO_REPLY: {
            action.title = 'Caso Respondido';
            action.content = <PayloadAction content={element.payload} />;
            action.icon = <FlowIcon flow={element.flow} />;
            return action;
        }
        case Types.NOTIFICATION: {
            action.title = 'Notificación';
            action.content = <PayloadAction content={element.payload} />;
            action.icon = <FlowIcon flow={element.flow} />;
            return action;
        }
        case Types.AUTO_CHANGE_SUBJECT: {
            action.title = 'Asunto Actualizado';
            action.content = (
                <SubjectChange
                    value={element.value}
                    original={element.original}
                />
            );
            action.icon = <FlowIcon flow={element.flow} />;
            return action;
        }
        case Types.AUTO_REPLY_ERROR:
            action.title = 'Error Respondiendo';
            action.icon = <FlowIcon flow={element.flow} />;
            action.content = (
                <ErrorAction
                    message={
                        'No fué posible responder automaticamente: ' +
                        element.error
                    }
                />
            );
            return action;

        case Types.NOTIFICATION_ERROR:
            action.title = 'Error Notificando';
            action.icon = <FlowIcon flow={element.flow} />;
            action.content = (
                <ErrorAction
                    message={
                        'No fué posible generar la notificación: ' +
                        element.error
                    }
                />
            );
            return action;
        case Types.TEMP_REPLY:
        case Types.REPLY: {
            action.title = (
                <React.Fragment>
                    <span className={styles.boldName}>
                        {element.agent.name}
                    </span>
                    Respondió
                </React.Fragment>
            );
            action.content = (
                <PayloadAction
                    content={element.payload}
                    tags={
                        (element as any).melendez_isFormal
                            ? { melendez_isFormal: true }
                            : element.tags
                    }
                />
            );
            if (element.type === Types.TEMP_REPLY) {
                action.bottom = (
                    <ActionBottom element={element} ticket={ticket} />
                );
            }
            action.icon = <ActionAvatar agent={element.agent} />;
            return action;
        }
        case Types.CLASSIFY: {
            action.title = 'Caso Clasificado';
            action.content = (
                <Classification classifiers={element.classifiers} />
            );
            action.icon = <ActionAvatar agent={element.agent} />;
            return action;
        }
        case Types.AUTO_CLASSIFY: {
            action.title = 'Caso Clasificado';
            action.content = (
                <Classification classifiers={element.classifiers} />
            );
            action.icon = <FlowIcon flow={element.flow} />;
            return action;
        }
        case Types.AUTO_CREATE_TICKET: {
            action.title = 'Caso Creado';
            action.content = <CreateTicket ticket={element.ticket} />;
            action.icon = <FlowIcon flow={element.flow} />;
            return action;
        }
        case Types.SET_COMPANY: {
            action.title = 'Empresa Identificada';
            action.content = (
                <SetCompany
                    originalCompany={element.originalCompany}
                    company={element.company}
                />
            );
            action.icon = <ActionAvatar agent={element.agent} />;
            return action;
        }
        case Types.SET_ENTITY: {
            const entity = entities[element.idEntity];
            const projectEntity = project.entities.find(
                (e) => e.idEntity === entity._id
            );
            if (!projectEntity || !entity) return null;
            action.title = `${
                projectEntity?.multiple ? entity.pluralName : entity.name
            } Identificad${entity.feminine ? 'a' : 'o'}${
                projectEntity?.multiple ? 's' : ''
            }`;
            action.content = (
                <SetEntities
                    name={
                        projectEntity?.multiple
                            ? entity.pluralName
                            : entity.name
                    }
                    entity={entity}
                    original={element.original}
                    value={element.value}
                />
            );
            action.icon = <ActionAvatar agent={element.agent} />;
            return action;
        }
        case Types.CHANGE_RESOLUTION_DATE: {
            action.title = 'Fecha de Vencimiento Actualizada';
            action.content = (
                <ChangeResolutionDate
                    originalResolutionDate={element.original_resolution_date}
                    resolutionDate={element.resolution_date}
                />
            );
            action.icon = <ActionAvatar agent={element.agent} />;
            return action;
        }
        case Types.CHANGE_STATE: {
            action.title = 'Estado Actualizado';
            action.content = <StateChange states={element.states} />;
            if (element.agent) {
                action.icon = <ActionAvatar agent={element.agent} />;
            }
            return action;
        }
        case Types.AUTO_CHANGE_STATE: {
            action.title = 'Estado Actualizado';
            action.content = <StateChange states={element.states} />;
            action.icon = <FlowIcon flow={element.flow} />;
            return action;
        }
        case Types.CHANGE_PROCESS:
        case Types.AUTO_CHANGE_PROCESS: {
            action.title = 'Proceso Actualizado';
            action.content = (
                <ProcessChange
                    idProject={element.idProject}
                    idOriginal={element.idOriginal}
                    idValue={element.idValue}
                    roles={element.roles}
                    values={element.values}
                    originalRoles={element.originalRoles}
                />
            );
            action.icon =
                element.type === Types.AUTO_CHANGE_PROCESS ? (
                    <FlowIcon flow={element.flow} />
                ) : (
                    <ActionAvatar agent={element.agent} />
                );
            return action;
        }
        case Types.AUTO_CHANGE_RESOLUTION_DATE: {
            action.title = 'Fecha de Vencimiento Actualizada';
            action.content = (
                <ChangeResolutionDate
                    originalResolutionDate={element.original_resolution_date}
                    resolutionDate={element.resolution_date}
                    hideOriginal={element.hideOriginal}
                />
            );
            action.icon = <FlowIcon flow={element.flow} />;
            return action;
        }
        case Types.CBR_POSVENTA_CREATE: {
            action.title = 'Posventa Generada';
            if (element.agent) {
                action.icon = <ActionAvatar agent={element.agent} />;
            }
            action.content = (
                <CBRPosVentaAction posventaCaseNum={element.posventaCaseNum} />
            );
            return action;
        }
        case Types.AUTO_CBR_POSVENTA_CREATE: {
            action.title = 'Posventa Generada';
            action.icon = <FlowIcon flow={element.flow} />;
            action.content = (
                <CBRPosVentaAction posventaCaseNum={element.posventaCaseNum} />
            );
            return action;
        }
        case Types.ERROR_AUTO_CBR_POSVENTA_CREATE:
            action.title = 'Error Generando Posventa';
            action.icon = <FlowIcon flow={element.flow} />;
            action.content = (
                <ErrorAction message="No fué posible generar la posventa automaticamente" />
            );
            return action;
        case Types.INTEGRATION_SUCCESS:
            action.title = element.titleMessage;
            action.content = (
                <IntegrationSuccessAction
                    message={element.message}
                    title={element.integration}
                />
            );
            return action;

        case Types.INTEGRATION_ERROR:
            action.title = element.titleMessage;
            action.content = <ErrorAction message={element.message} />;
            return action;
        case Types.ACTIVITY:
            //TODO: Potencialmente indicar si es un proceso viejo y poner el nombre!
            const activity =
                processes[element.idProcess]?.activities[element.idActivity];
            if (!activity) return null;
            action.title = activity.name;
            switch (element.activityType) {
                case ActivityTypes.FORM:
                    if (activity.type !== element.activityType) return null;
                    if (
                        !element.agent ||
                        !element.values ||
                        element.idTrigger === undefined
                    )
                        return null;
                    action.icon = <ActionAvatar agent={element.agent} />;
                    action.content = (
                        <FormActivityAction
                            activity={activity}
                            values={element.values}
                            message={element.message}
                            idTrigger={element.idTrigger}
                        />
                    );
                    break;
                case ActivityTypes.CLIENT_FORM:
                    if (activity.type !== element.activityType) return null;
                    if (!element.values || element.idTrigger === undefined)
                        return null;
                    action.content = (
                        <FormActivityAction
                            activity={activity}
                            values={element.values}
                            message={element.message}
                            idTrigger={element.idTrigger}
                        />
                    );
                    break;
                case ActivityTypes.VIEW_FORM:
                case ActivityTypes.CLIENT_VIEW_FORM:
                    if (activity.type !== element.activityType) return null;
                    if (
                        activity.type === ActivityTypes.CLIENT_VIEW_FORM &&
                        activity.showInTrace === false
                    )
                        return null;
                    if (!element.values) return null;
                    action.content = (
                        <ViewFormActivityAction
                            activity={activity}
                            values={element.values}
                        />
                    );
                    break;
                case ActivityTypes.CLIENT_FORM:
                    if (activity.type !== element.activityType) return null;
                    if (!element.values || element.idTrigger === undefined)
                        return null;
                    action.content = (
                        <FormActivityAction
                            activity={activity}
                            values={element.values}
                            message={element.message}
                            idTrigger={element.idTrigger}
                        />
                    );
                    break;
                case ActivityTypes.DATE:
                    if (activity.type !== element.activityType) return null;
                    if (element.agent && element.idTrigger && element.values) {
                        action.icon = <ActionAvatar agent={element.agent} />;
                        action.content = (
                            <FormActivityAction
                                activity={activity}
                                message={element.message}
                                values={element.values}
                                idTrigger={element.idTrigger}
                            />
                        );
                    } else {
                        return null;
                    }
                    break;
                case ActivityTypes.SCHEDULE:
                case ActivityTypes.SCHEDULE_CANCEL:
                    action.title =
                        'Visita ' +
                        (element.activityType === ActivityTypes.SCHEDULE_CANCEL
                            ? 'Cancelada'
                            : 'Agendada');
                    action.content = (
                        <DateActivityAction
                            date={element.scheduleDate.startDate}
                            subject={element.subject}
                            body={element.body}
                            location={element.location}
                            cancelled={
                                element.activityType ===
                                ActivityTypes.SCHEDULE_CANCEL
                            }
                        />
                    );
                    action.icon = <FlowIcon />;
                    return action;
                default:
                    return null;
            }
            return action;
        default:
            return null;
    }
}
