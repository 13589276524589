import styles from './AgentsMenu.module.css';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import Search from '../../../../../../shared/Search/Search';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ActionsService from '../../../../../../controllers/TicketsController/Actions/ActionsService';
import { useDispatch, useSelector } from 'react-redux';
import { Area, Ticket } from '../../../../../../@Types/@Types';
import TransferAgent from './TransferAgent/TransferAgent';
import TransferArea from './TransferArea/TransferArea';
import Assign from './AssignAgents/Assign';
import AreasFilter from '../../../../../../shared/AreasFilter/AreasFilter';
import { RootState } from '../../../../../../utils/_store';
import {
    resetAssign,
    filter as filterAssignAgents,
    search as searchAssign,
    setLoading as setLoadingAssign,
    toggleFilters as toggleAssignFilters,
} from '../../../../../../controllers/TicketsController/AssignAgents/AgentsActions';
import {
    resetTransfer,
    filter as filterTransferAgents,
    search as searchTransfer,
    setLoading as setLoadingTransfer,
    toggleFilters as toggleTransferFilters,
} from '../../../../../../controllers/TicketsController/TransferAgent/AgentsActions';
import { loadAreas } from '../../../../../../controllers/AreasController/AreasService';
import Dialog from '../../../../../../shared/Dialog/Dialog';
import AssignArea from './AssignArea/AssignArea';
import Toggle from '../../../../../../shared/Toggle/Toggle';
import { RouterProps, withRouter } from 'react-router-dom';
import { appendAction } from '../../../../../../controllers/TicketsController/Actions/ActionsActions';
import VanillaToasts from '../../../../../../shared/Toast/Toast';
import RoundedButton from '../../../../../../shared/RoundedButton/RoundedButton';

type Mode =
    | 'ASSIGN_AGENTS'
    | 'ASSIGN_AREAS'
    | 'TRANSFER_AGENTS'
    | 'TRANSFER_AREAS';

//TODO achicar este archivo!
interface AgentsMenuProps {
    /** The ref of the anchor, the Agents button on the ticket detail */
    anchorRef: any;
    /** Function to call to close the menu */
    handleClose: Function;
    /** The currently selected Ticket */
    selectedElement: Ticket;
    /** If the layout is mobile */
    layoutIsMobile?: boolean;
}
function AgentsMenu({
    anchorRef,
    handleClose,
    layoutIsMobile,
    selectedElement,
}: AgentsMenuProps & RouterProps): JSX.Element {
    const dispatch = useDispatch();
    const [mode, setMode] = useState<Mode>('ASSIGN_AGENTS');
    const [loadingAutoScaling, setLoadingAutoScaling] = useState(false);
    const emptyRef = useRef<HTMLDivElement>(null);
    const siteInfo = useSelector((state: RootState) => state.site);
    const agentsAssignInfo = useSelector(
        (state: RootState) => state.ticketAssignAgents
    );
    const agentsTransferInfo = useSelector(
        (state: RootState) => state.ticketTransferAgents
    );
    const [areas, setAreas] = useState<Area[]>([]);
    const assignListRef = useRef<HTMLDivElement>(null);
    const transferListRef = useRef<HTMLDivElement>(null);
    const [areaSearch, setAreaSearch] = useState<string | undefined>(undefined);
    const loading = useSelector((state: RootState) =>
        mode === 'ASSIGN_AGENTS'
            ? state.ticketAssignAgents.loading
            : state.ticketTransferAgents.loading
    );
    useEffect(() => {
        dispatch(resetAssign(selectedElement.agents));
        dispatch(resetTransfer());
        fetchAreas();
    }, []);

    const autoscaling =
        selectedElement.config?.autoscaling ??
        siteInfo.organization?.idOrganization === 'prodesa';
    /**
     * On load fetch the areas and store them in a state
     */
    const fetchAreas = async (): Promise<void> => {
        if (siteInfo.idProject) {
            try {
                const response = await loadAreas(
                    siteInfo.idProject,
                    'order',
                    undefined
                );
                setAreas(
                    Object.values(response.elements).filter(
                        (area) => area.active
                    )
                );
            } catch (error) {
                //TODO handle errors
                console.error(error);
            }
        }
    };

    /**
     * Function that changes the current mode of the menu between assign and transfer
     */
    const changeMode = (): void => {
        if (mode === 'ASSIGN_AGENTS') {
            setMode('TRANSFER_AGENTS');
        } else if (mode === 'TRANSFER_AGENTS') {
            setMode('ASSIGN_AGENTS');
        } else if (mode === 'ASSIGN_AREAS') {
            setMode('TRANSFER_AREAS');
        } else if (mode === 'TRANSFER_AREAS') {
            setMode('ASSIGN_AREAS');
        }
    };

    /**
     * Function called when user clicks out of the menu, only saves the changes in the assign tab
     */
    const saveChanges = async (): Promise<void> => {
        if (mode === 'ASSIGN_AGENTS') {
            const chngs = Object.keys(agentsAssignInfo.changes);
            /** Close the menu instantly */
            handleClose();
            const assignees = [];
            const removees = [];
            for (const change of chngs) {
                //Check if existed before in the ticket
                const found =
                    selectedElement.agents.find(
                        (agent) => agent._id === change
                    ) !== undefined;
                if (agentsAssignInfo.changes[change] && !found) {
                    assignees.push(change);
                } else if (!agentsAssignInfo.changes[change] && found) {
                    removees.push(change);
                }
            }
            /** If new assigns are found tell the service */
            if (assignees.length > 0) {
                try {
                    const action = await ActionsService.assignAgents(
                        selectedElement.idProject,
                        selectedElement._id,
                        assignees
                    );
                    dispatch(appendAction(action));
                } catch (error) {
                    VanillaToasts.create({
                        title: 'Error al assignar',
                        text: error,
                        type: 'error',
                        timeout: 3000,
                    });
                    console.error(error);
                }
            }
            /** If some agents where removed tell the server */
            if (removees.length > 0) {
                for (const removee of removees) {
                    try {
                        const action = await ActionsService.removeAgent(
                            selectedElement.idProject,
                            selectedElement._id,
                            removee
                        );
                        dispatch(appendAction(action));
                    } catch (error) {
                        VanillaToasts.create({
                            title: 'Error al remover el agente',
                            text: error,
                            type: 'error',
                            timeout: 3000,
                        });
                        console.error(error);
                    }
                }
            }
        } else {
            handleClose();
        }
    };

    /**
     * Function that returns the title of the menu depending on what the user is doing
     */
    const calcTitle = (): string => {
        if (mode === 'ASSIGN_AGENTS') {
            return 'Asignar Agentes';
        } else if (mode === 'ASSIGN_AREAS') {
            return 'Asignar Area';
        } else if (mode === 'TRANSFER_AGENTS') {
            return 'Transferir a Agente';
        } else if (mode === 'TRANSFER_AREAS') {
            return 'Transferir a Area';
        } else {
            return '';
        }
    };

    /**
     * Funtion that returns the tabs to switch between agents and areas
     */
    const renderTabs = (): JSX.Element => {
        return (
            <div className={styles.tabsContainer}>
                <div
                    className={styles.agentsTab + ' noselect'}
                    style={
                        mode === 'ASSIGN_AGENTS' || mode === 'TRANSFER_AGENTS'
                            ? {
                                  borderTop: '1px solid var(--light-grey)',
                                  borderLeft: '1px solid var(--light-grey)',
                                  borderRight: '1px solid var(--light-grey)',
                                  backgroundColor: 'var(--primary1)',
                              }
                            : {
                                  borderBottom: '1px solid var(--light-grey)',
                                  backgroundColor: 'transparent',
                                  cursor: 'pointer',
                              }
                    }
                    onClick={(): void => {
                        if (mode === 'ASSIGN_AREAS') {
                            setMode('ASSIGN_AGENTS');
                        } else if (mode === 'TRANSFER_AREAS') {
                            setMode('TRANSFER_AGENTS');
                        }
                    }}
                >
                    Agentes
                </div>
                <div
                    className={styles.areasTab + ' noselect'}
                    style={
                        mode === 'ASSIGN_AREAS' || mode === 'TRANSFER_AREAS'
                            ? {
                                  borderTop: '1px solid var(--light-grey)',
                                  borderLeft: '1px solid var(--light-grey)',
                                  borderRight: '1px solid var(--light-grey)',
                                  backgroundColor: 'var(--primary1)',
                              }
                            : {
                                  borderBottom: '1px solid var(--light-grey)',
                                  backgroundColor: 'transparent',
                                  cursor: 'pointer',
                              }
                    }
                    onClick={(): void => {
                        if (mode === 'ASSIGN_AGENTS') {
                            setMode('ASSIGN_AREAS');
                        } else if (mode === 'TRANSFER_AGENTS') {
                            setMode('TRANSFER_AREAS');
                        }
                    }}
                >
                    Areas
                </div>
            </div>
        );
    };

    /**
     * Function that returns the list of elements depending on the current mode
     */
    const renderOptions = (): JSX.Element => {
        if (mode === 'ASSIGN_AGENTS') {
            return <Assign listRef={assignListRef} />;
        } else if (mode === 'ASSIGN_AREAS') {
            return (
                <AssignArea
                    search={areaSearch}
                    handleClose={handleClose}
                    selectedTicket={selectedElement}
                    areas={areas}
                />
            );
        } else if (mode === 'TRANSFER_AGENTS') {
            return (
                <TransferAgent
                    listRef={transferListRef}
                    handleClose={handleClose}
                    selectedElement={selectedElement}
                />
            );
        } else {
            return (
                <TransferArea
                    search={areaSearch}
                    handleClose={handleClose}
                    selectedTicket={selectedElement}
                    areas={areas}
                />
            );
        }
    };
    /**
     * Function that calcs if filters should be currently shown
     */
    const calcShowFilters = (): boolean => {
        if (mode === 'ASSIGN_AGENTS') {
            return agentsAssignInfo.showFilters;
        } else if (mode === 'TRANSFER_AGENTS') {
            return agentsTransferInfo.showFilters;
        } else {
            return false;
        }
    };

    /**
     *  Function that returns the value to display in the searchbar, this is controlled because each list can have different values
     */
    const calcSearch = (): string | undefined => {
        if (mode === 'ASSIGN_AREAS') {
            return agentsAssignInfo.search;
        } else if (mode === 'TRANSFER_AGENTS') {
            return agentsTransferInfo.search;
        } else {
            return areaSearch;
        }
    };

    const handleAutoScaling = useCallback(async () => {
        try {
            setLoadingAutoScaling(true);
            const action = await ActionsService.autoscale(
                selectedElement.idProject,
                selectedElement._id
            );
            dispatch(appendAction(action));
            handleClose();
        } catch (error) {
            setLoadingAutoScaling(false);
            VanillaToasts.create({
                title: 'Error al autoescalar',
                text: error,
                type: 'error',
                timeout: 3000,
            });
            console.error(error);
        }
    }, []);

    const renderContent = (): JSX.Element => {
        return (
            <div
                className={
                    layoutIsMobile
                        ? styles.mobileContainer
                        : styles.emptyContainer
                }
                style={{
                    pointerEvents: loadingAutoScaling ? 'none' : 'all',
                }}
                onClick={(e): void => {
                    if (emptyRef.current === e.target) {
                        saveChanges();
                    }
                }}
                ref={emptyRef}
            >
                <div className={styles.container}>
                    <div className={styles.title}>
                        {calcTitle()}
                        <div className={styles.transferBtn + ' noselect'}>
                            Transferir:
                            <div className={styles.transferToggle}>
                                <Toggle
                                    size={'small'}
                                    checked={
                                        mode === 'TRANSFER_AGENTS' ||
                                        mode === 'TRANSFER_AREAS'
                                    }
                                    onChange={(): void => {
                                        changeMode();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className={styles.searchContainer}
                        data-testid={'AgentsMenu_Search_Container'}
                    >
                        <Search
                            wait={500}
                            placeholder={
                                'Buscar ' +
                                (mode === 'ASSIGN_AGENTS' ||
                                mode === 'TRANSFER_AGENTS'
                                    ? 'Agentes'
                                    : 'Areas')
                            }
                            search={calcSearch()}
                            handleLoadingSearch={(loading: boolean): void => {
                                if (mode === 'TRANSFER_AGENTS') {
                                    dispatch(setLoadingTransfer(loading));
                                } else if (mode === 'ASSIGN_AGENTS') {
                                    dispatch(setLoadingAssign(loading));
                                }
                            }}
                            handleSearch={(value: any): void => {
                                if (mode === 'TRANSFER_AGENTS') {
                                    if (transferListRef.current)
                                        transferListRef.current.scrollTop = 0;
                                    dispatch(searchTransfer(value));
                                } else if (mode === 'ASSIGN_AGENTS') {
                                    if (assignListRef.current)
                                        assignListRef.current.scrollTop = 0;
                                    dispatch(searchAssign(value));
                                } else {
                                    setAreaSearch(value);
                                }
                            }}
                        />
                        {!['TRANSFER_AREAS', 'ASSIGN_AREAS'].includes(mode) && (
                            <div
                                className={styles.filterBtn}
                                onClick={(): void => {
                                    if (mode === 'TRANSFER_AGENTS') {
                                        dispatch(toggleTransferFilters());
                                    } else {
                                        dispatch(toggleAssignFilters());
                                    }
                                }}
                                style={{
                                    border: calcShowFilters()
                                        ? '1px solid var(--dark-grey)'
                                        : '1px solid rgba(0, 0, 0, 0.23)',
                                }}
                            >
                                <img
                                    className={styles.filterIcon}
                                    src={`/media/icons/greyFilterIcon${
                                        calcShowFilters() ? '-Dark' : ''
                                    }.svg`}
                                    alt={'filterIcon'}
                                />
                            </div>
                        )}
                    </div>
                    {calcShowFilters() && (
                        <div className={styles.filtersContainer}>
                            <div className={styles.areasFilters}>
                                Area:
                                <div className={styles.areasDropDownContainer}>
                                    <AreasFilter
                                        options={areas}
                                        value={
                                            mode === 'ASSIGN_AGENTS'
                                                ? agentsAssignInfo.areafilter
                                                : mode === 'TRANSFER_AGENTS'
                                                ? agentsTransferInfo.areafilter
                                                : null
                                        }
                                        handleUpdate={(area: Area): void => {
                                            if (mode === 'TRANSFER_AGENTS') {
                                                dispatch(
                                                    filterTransferAgents(area)
                                                );
                                            } else if (
                                                mode === 'ASSIGN_AGENTS'
                                            ) {
                                                dispatch(
                                                    filterAssignAgents(area)
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {renderTabs()}
                    {renderOptions()}
                    {autoscaling && !loading && (
                        <div className={styles.autoScaling}>
                            <RoundedButton
                                loading={loadingAutoScaling}
                                text="Autoescalar"
                                onClick={handleAutoScaling}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };
    if (layoutIsMobile) {
        return (
            <Dialog
                onClose={(): void => {
                    if (!loadingAutoScaling) saveChanges();
                }}
                maxWidth="100vw"
                border={10}
                transparent
            >
                {renderContent()}
            </Dialog>
        );
    } else {
        return (
            <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={(): void => {
                    if (!loadingAutoScaling) saveChanges();
                }}
            >
                <Popper
                    open={true}
                    anchorEl={anchorRef.current}
                    placement={'bottom-end'}
                    modifiers={{
                        preventOverflow: {
                            enabled: true,
                            priority: ['top', 'bottom', 'left', 'right'],
                            boundariesElement: 'viewport',
                            padding: 20,
                        },
                    }}
                    style={{ zIndex: 2 }}
                >
                    {renderContent()}
                </Popper>
            </ClickAwayListener>
        );
    }
}
export default withRouter((props: any) => <AgentsMenu {...props} />);
