import { useSelector } from 'react-redux';
import {
    CurrentTicketActivity,
    Ticket,
} from '../../../../@Types/TicketTypes/Ticket';
import { ActivityTypes } from '../../../../constants/ActivityTypes/ActivityTypes';
import { RootState } from '../../../../utils/_store';
import FormActivity from './FormActivity/FormActivity';
import DateActivity from './DateActivity/DateActivity';
import { checkProjPerm } from '../../../../utils/PermissionsFunctions';
import { ProjectPermissions } from '../../../../constants/Permissions';
import PreReplyActivity from './PreReplyActivity/PreReplyActivity';
import { ActivityPersona } from '../../../../@Types/ProcessTypes/Persona';

export interface ActivityProps {
    current: CurrentTicketActivity;
    ticket: Ticket;
}

function Activity({ current, ticket }: ActivityProps): JSX.Element | null {
    const process = useSelector(
        (state: RootState) =>
            state.ticketsPage.processes[ticket.process?.idProcess ?? '']
    );
    const idProject = useSelector((state: RootState) => state.site.idProject);
    const user = useSelector((state: RootState) => state.site.user!);

    function canComplete(
        activity: { agents: ActivityPersona[] | null },
        agents: string[]
    ): boolean {
        return (
            ticket.state.type !== 'CLOSED' &&
            ((activity.agents &&
                (agents.includes(user._id) ||
                    !!ticket.agents.find((agent) => agent._id === user._id))) ||
                checkProjPerm(
                    user,
                    idProject,
                    ProjectPermissions.MANAGE_NOT_ASSIGNED
                ))
        );
    }

    if (!process || !ticket.process) return null;
    const ticketActivity = ticket.process.activities[current.idActivity]?.find(
        (act) => act.path === current.path
    );

    if (!ticketActivity) return null;
    const activity = process.activities[current.idActivity];
    if (!activity) return null;
    switch (ticketActivity.type) {
        case ActivityTypes.FORM:
            if (activity.type !== ticketActivity.type) return null;
            if (current.type !== ticketActivity.type) return null;
            if (!canComplete(activity, ticketActivity.agents)) return null;
            return (
                <FormActivity
                    ticket={ticket}
                    activity={activity}
                    originalValues={current.values}
                />
            );
        case ActivityTypes.PRE_REPLY:
            if (activity.type !== ticketActivity.type) return null;
            if (current.type !== ticketActivity.type) return null;
            if (!canComplete(activity, ticketActivity.agents)) return null;
            return (
                <PreReplyActivity
                    ticket={ticket}
                    activity={activity}
                    ticketActivity={ticketActivity}
                />
            );
        case ActivityTypes.DATE:
            if (activity.type !== ticketActivity.type) return null;
            if (
                !canComplete(activity, ticketActivity.agents) ||
                activity.triggers.length === 0
            )
                return null;
            return <DateActivity ticket={ticket} activity={activity} />;
        default:
            return null;
    }
}

export default Activity;
