import styles from './Condition.module.css';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useCallback, useState } from 'react';
import ClassifierConditionComponent from './Conditions/ClassifierCondition';
import TextConditionComponent from './Conditions/TextCondition';
import SentimentConditionComponent from './Conditions/SentimentCondition';
import ClientConditionComponent from './Conditions/ClientCondition/ClientCondition';
import EntityConditionComponent from './Conditions/EntityCondition/EntityCondition';
import ConditionTypes from '../../constants/Conditions/ConditionTypes';
import { NonExpressionCondition } from '../../@Types/ConditionTypes/Condition';
import RoundedConditionPicker from '../@Pickers/RoundedConditionPicker/RoundedConditionPicker';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import {
    addExpression,
    deleteCondition,
    toggleExpression,
    updateCondition,
    useConditionEditorDispatch,
    useConditionEditorSelector,
} from '../../controllers/ConditionEditorController/ConditionEditorSlice';
import ExpressionTypes from '../../constants/Conditions/ExpressionTypes';
import { ConditionContext } from './ConditionEditor';
import ConversationStepConditionComponent from './Conditions/ConversationStepCondition';
import FormStepConditionComponent from './Conditions/FormStepCondition/FormStepCondition';
import FlowConditionComponent from './Conditions/FlowCondition';
import EntryConditionComponent from './Conditions/EntryCondition/EntryCondition';

export interface ConditionProps<C extends NonExpressionCondition> {
    condition: C;
    /** if the select should show error */
    hoverDelete: boolean;
    context: ConditionContext;
}

interface ConditionComponentProps {
    level?: number;
    allowEmpty: boolean;
    idCondition: string;
    children?: React.ReactNode;
    conditionTypes: ConditionTypes[];
    handleDelete?: () => void;
    context: ConditionContext;
}

function ConditionComponent({
    level = 0,
    context,
    children,
    allowEmpty,
    idCondition,
    handleDelete,
    conditionTypes,
}: ConditionComponentProps): JSX.Element {
    const [hoverDelete, setHoverDelete] = useState(false);
    const condition = useConditionEditorSelector(
        (state) => state?.conditions[idCondition]
    );
    const dispatch = useConditionEditorDispatch();

    const handleUpdate = useCallback(
        (condition: NonExpressionCondition): void => {
            dispatch(
                updateCondition({
                    idCondition,
                    condition,
                })
            );
        },
        [condition]
    );

    if (!condition) return <div></div>;
    const renderCondition = (): JSX.Element => {
        if (condition.type === ConditionTypes.EXPRESSION) {
            return (
                <React.Fragment>
                    {condition.conditions.map((idSubCondition, index) => (
                        <React.Fragment key={idSubCondition}>
                            <ConditionComponent
                                context={context}
                                level={level + 1}
                                allowEmpty={allowEmpty}
                                idCondition={idSubCondition}
                                conditionTypes={conditionTypes}
                                handleDelete={(): void => {
                                    dispatch(
                                        deleteCondition({
                                            idCondition,
                                            index,
                                        })
                                    );
                                }}
                            />
                            {index < condition.conditions.length - 1 && (
                                <div className={styles.expression}>
                                    <div className={styles.expressionHr}></div>
                                    <div
                                        className={styles.expressionBtn}
                                        onClick={(): void => {
                                            dispatch(
                                                toggleExpression(idCondition)
                                            );
                                        }}
                                    >
                                        <label
                                            className={
                                                styles.expressionLbl +
                                                ' noselect'
                                            }
                                        >
                                            {condition.expression ===
                                            ExpressionTypes.AND
                                                ? 'Y'
                                                : 'O'}
                                        </label>
                                    </div>
                                    <div className={styles.expressionHr}></div>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </React.Fragment>
            );
        }
        return (
            <div className={styles.conditionContainer}>
                <div className={styles.selectorContainer}>
                    <RoundedConditionPicker
                        value={
                            condition.type === ConditionTypes.ENTITYVALUE
                                ? (condition as any).idEntity
                                : (condition.type as any)
                        }
                        label="Condición"
                        context={context}
                        types={conditionTypes}
                        error={hoverDelete}
                        handleUpdate={(type): void => {
                            if (type === ConditionTypes.EXPRESSION) {
                                dispatch(addExpression(idCondition));
                            } else if (ConditionTypes[type as ConditionTypes]) {
                                handleUpdate({
                                    type,
                                } as any);
                            } else {
                                handleUpdate({
                                    type: ConditionTypes.ENTITYVALUE,
                                    idEntity: type,
                                } as any);
                            }
                        }}
                    />
                </div>
                {condition.type !== 'DEFAULT' && (
                    <NonExpressionConditionMapper
                        context={context}
                        condition={condition}
                        hoverDelete={hoverDelete}
                        handleUpdate={handleUpdate}
                    />
                )}
                {handleDelete &&
                    (level > 0 ||
                        (allowEmpty && condition.type !== 'DEFAULT')) && (
                        <div className={styles.removeContainer}>
                            <div
                                className={styles.removeConditionContainer}
                                style={
                                    hoverDelete
                                        ? {
                                              color: 'var(--error)',
                                              borderColor: 'var(--error)',
                                          }
                                        : {}
                                }
                                onClick={(): void => {
                                    handleDelete();
                                    setHoverDelete(false);
                                }}
                                onMouseEnter={(): void => setHoverDelete(true)}
                                onMouseLeave={(): void => setHoverDelete(false)}
                                title={'Eliminar'}
                            >
                                <DeleteIcon fontSize="inherit" />
                            </div>
                        </div>
                    )}
            </div>
        );
    };

    if (condition.type === ConditionTypes.EXPRESSION || !level || children) {
        return (
            <div
                className={styles.expressionContainer}
                style={{
                    borderColor: hoverDelete
                        ? 'var(--error)'
                        : 'var(--secondary2)',
                    margin: level > 0 ? '10px 20px' : undefined,
                    width: level > 0 ? 'calc(100% - 40px)' : undefined,
                    backgroundColor:
                        level % 2 === 0 ? 'var(--primary1)' : 'var(--primary2)',
                }}
            >
                {handleDelete && (level || !allowEmpty) && (
                    <div
                        className={styles.deleteContainer}
                        onMouseEnter={(): void => {
                            setHoverDelete(true);
                        }}
                        onMouseLeave={(): void => {
                            setHoverDelete(false);
                        }}
                        onClick={(): void => {
                            handleDelete();
                            setHoverDelete(false);
                        }}
                    >
                        <DeleteIcon fontSize="inherit" />
                    </div>
                )}
                {children}
                <div className={styles.expressionConditionsContainer}>
                    {renderCondition()}
                </div>
                <div
                    className={styles.addCondition}
                    onClick={(): void => {
                        dispatch(addExpression(idCondition));
                    }}
                >
                    <div className={styles.addConditionIconContainer}>
                        <AddRoundedIcon fontSize="inherit" />
                    </div>
                </div>
            </div>
        );
    } else {
        return renderCondition();
    }
}

export default ConditionComponent;

export interface ConditionProps<C extends NonExpressionCondition> {
    condition: C;
    /** Function called when condition has been updated */
    handleUpdate: (condition: C) => void;
    /** if the select should show error */
    hoverDelete: boolean;
}

function NonExpressionConditionMapper({
    condition,
    ...others
}: ConditionProps<NonExpressionCondition>): JSX.Element {
    switch (condition.type) {
        case ConditionTypes.CLASSIFIER: {
            return (
                <ClassifierConditionComponent
                    condition={condition}
                    {...others}
                />
            );
        }
        case ConditionTypes.TEXT: {
            return <TextConditionComponent condition={condition} {...others} />;
        }
        case ConditionTypes.CLIENT: {
            return (
                <ClientConditionComponent condition={condition} {...others} />
            );
        }
        case ConditionTypes.SENTIMENT: {
            return (
                <SentimentConditionComponent
                    condition={condition}
                    {...others}
                />
            );
        }
        case ConditionTypes.ENTRY: {
            return (
                <EntryConditionComponent condition={condition} {...others} />
            );
        }
        case ConditionTypes.COMPANY: {
            return (
                <EntityConditionComponent
                    condition={condition as any}
                    {...others}
                />
            );
        }
        case ConditionTypes.ENTITYVALUE: {
            return (
                <EntityConditionComponent condition={condition} {...others} />
            );
        }
        case ConditionTypes.FLOW: {
            return <FlowConditionComponent condition={condition} {...others} />;
        }
        case ConditionTypes.CONVERSATION_STEP: {
            return (
                <ConversationStepConditionComponent
                    condition={condition}
                    {...others}
                />
            );
        }
        case ConditionTypes.FORM_STEP: {
            return (
                <FormStepConditionComponent condition={condition} {...others} />
            );
        }
        default: {
            return <div></div>;
        }
    }
}
