import { Dialog, Fab, IconButton, makeStyles } from '@material-ui/core';
import { Ticket } from '../../../../@Types/@Types';
import styles from './MobileContentDialog.module.css';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ContentList from '../ContentList/ContentList';
import MobileActionBar from './MobileActionBar/MobileActionBar';
import { ProjectPermissions } from '../../../../constants/Permissions';
import { RootState } from '../../../../utils/_store';
import { checkTicketPerm } from '../../../../utils/PermissionsFunctions';
import { useSelector } from 'react-redux';
import {
    withRouter,
    RouteComponentProps,
    Switch,
    Route,
    useRouteMatch,
} from 'react-router-dom';
const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: 'var(--secondary)',
        '&:hover': {
            backgroundColor: 'var(--secondary)',
        },
    },
}));

interface MobileContentListProps extends RouteComponentProps {
    /** The currently displayed ticket */
    selectedElement: Ticket;
    /** If the current ticket is closed */
    isClosed: boolean;
}

function MobileContentList({
    selectedElement,
    isClosed,
    history,
}: MobileContentListProps): JSX.Element {
    let { path } = useRouteMatch();
    const btnClasses = useStyles();
    const user = useSelector((state: RootState) => state.site.user);

    /** If the current user can reply to the client */
    const canReply =
        /** Ticket is not closed */
        !isClosed &&
        /** Element is not child */
        !selectedElement.path &&
        checkTicketPerm(user, selectedElement, ProjectPermissions.REPLY);

    /** If the current user can comment the ticket */
    const canComment = checkTicketPerm(user, selectedElement, null);

    const open = (): void => {
        const url = history.location.pathname + '/activity';
        history.push({
            pathname: url,
            search: history.location.search,
            state: { prevPath: history.location.pathname },
        });
    };

    const close = (): void => {
        if (
            (history.location?.state as any)?.prevPath ===
            history.location.pathname.replace('/activity', '')
        ) {
            history.goBack();
        } else {
            const url =
                history.location.pathname.replace('/activity', '') +
                history.location.search;
            history.push(url);
        }
    };

    return (
        <Switch>
            <Route path={path + '/activity'}>
                <Dialog
                    disableEnforceFocus={true}
                    PaperProps={{
                        style: {
                            borderRadius: 0,
                            height: '100%',
                            margin: 0,
                            width: '100vw',
                            maxHeight: '100%',
                            maxWidth: '100vw',
                        },
                    }}
                    onClose={(): void => {
                        close();
                    }}
                    open={true}
                >
                    <div className={styles.container}>
                        <div className={styles.appBar}>
                            <div className={styles.backBtn}>
                                <IconButton
                                    color="inherit"
                                    onClick={(): void => {
                                        close();
                                    }}
                                >
                                    <ArrowBackRoundedIcon fontSize="inherit" />
                                </IconButton>
                            </div>
                        </div>
                        <ContentList
                            canComment={canComment}
                            showPayload
                            layoutIsMobile={true}
                            selectedElement={selectedElement}
                        />
                        <MobileActionBar
                            canComment={canComment}
                            canReply={canReply}
                            selectedElement={selectedElement}
                        />
                    </div>
                </Dialog>
            </Route>
            <Route path="*">
                <div className={styles.btnContainer}>
                    <Fab
                        className={btnClasses.root}
                        onClick={(): void => {
                            open();
                        }}
                    >
                        <div className={styles.interaction}>
                            {/* <div className={styles.badge}>10</div> */}
                            <img
                                alt={'Interaction'}
                                className={styles.interactionImg}
                                src={'/media/icons/whiteChatIcon.svg'}
                            ></img>
                        </div>
                    </Fab>
                </div>
            </Route>
        </Switch>
    );
}
export default withRouter(MobileContentList);
