import styles from './SubTicketsSideBar.module.css';
import React, { useRef } from 'react';
import { Ticket } from '../../../../../@Types/@Types';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import StatePill from '../../../../../shared/StatePill/StatePill';
import { RouterProps, withRouter } from 'react-router-dom';
import { goTo } from '../../../../../controllers/TicketsController/TicketsActions';
import { useDispatch, useSelector } from 'react-redux';
import { checkTicketPerm } from '../../../../../utils/PermissionsFunctions';
import { ProjectPermissions } from '../../../../../constants/Permissions';
import { RootState } from '../../../../../utils/_store';
interface SubTicketsSideBarProps extends RouterProps {
    /** currently displayed ticket */
    selectedElement: Ticket;
    /**State than handles the display of the create subticket's dialog */
    setCreateSubTicket: Function;
}
function SubTicketsSideBar({
    history,
    selectedElement,
    setCreateSubTicket,
}: SubTicketsSideBarProps): JSX.Element {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.site.user);
    const headerRef = useRef<HTMLDivElement>(null);

    /** If the current user can close the ticket */
    const canCreate = checkTicketPerm(
        user,
        selectedElement,
        ProjectPermissions.CREATE_SUBTICKET
    );

    const calcSUbTickets = (): JSX.Element[] => {
        const subTickets: JSX.Element[] = [];
        selectedElement.subTickets?.forEach((element, index) => {
            subTickets.push(
                <div
                    className={styles.subTicketSubject}
                    key={index}
                    onClick={(): void => {
                        history.push(
                            `/${element.idProject}/tickets/${element._id}`
                        );
                        dispatch(
                            goTo({
                                idTicket: element._id,
                                queryString: '',
                            })
                        );
                    }}
                    title={element.subject ?? element.caseNumber}
                >
                    <label className={styles.subjectLabel}>
                        {element.subject ?? element.caseNumber}
                    </label>
                    <div className={styles.pillContainer}>
                        <StatePill
                            state={undefined}
                            idState={element.state as unknown as string}
                            hideLabel={true}
                        />
                    </div>
                </div>
            );
        });
        return subTickets;
    };
    return (
        <div className={styles.container}>
            <div ref={headerRef} className={styles.header + ' noselect'}>
                <div ref={headerRef} className={styles.subHeader + ' noselect'}>
                    <label className={styles.titleLbl}>SubCasos</label>
                    {canCreate && (
                        <div
                            className={styles.editContainer}
                            onClick={(): void => {
                                setCreateSubTicket(true);
                            }}
                        >
                            <AddRoundedIcon fontSize="inherit" />
                        </div>
                    )}
                </div>
                <div className={styles.subTicketContainer}>
                    {calcSUbTickets()}
                </div>
            </div>
        </div>
    );
}
export default withRouter((props: any) => <SubTicketsSideBar {...props} />);
