import styles from './ProcessSideBar.module.css';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { useState, useRef } from 'react';
import { Ticket } from '../../../../../@Types/@Types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../utils/_store';
import ProcessRenderer from '../../../../../shared/ProcessRenderer/ProcessRenderer';
import { ProjectPermissions } from '../../../../../constants/Permissions';
import { checkTicketPerm } from '../../../../../utils/PermissionsFunctions';
import React from 'react';
import ProcessMenu from './ProcessMenu/ProcessMenu';

interface ProcessSideBarProps {
    /** The currently selected ticket */
    ticket: Ticket;
    /** If the layout is mobile */
    layoutIsMobile?: boolean;
}
function ProcessSideBar({
    ticket,
    layoutIsMobile,
}: ProcessSideBarProps): JSX.Element {
    const [hovering, setHovering] = useState(false);
    const user = useSelector((state: RootState) => state.site.user);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const headerRef = useRef<HTMLDivElement>(null);
    const process = useSelector(
        (state: RootState) =>
            state.ticketsPage.processes[ticket.process?.idProcess ?? '']
    );
    /** If the current user can change the process of the ticket */
    const canChange =
        checkTicketPerm(user, ticket, ProjectPermissions.CHANGE_PROCESS) &&
        !ticket.state.controlled;

    return (
        <React.Fragment>
            {menuOpen && (
                <ProcessMenu
                    layoutIsMobile={layoutIsMobile}
                    handleClose={(): void => {
                        setMenuOpen(false);
                    }}
                    anchorRef={headerRef}
                    ticket={ticket}
                />
            )}
            <div className={styles.container}>
                <div
                    ref={headerRef}
                    className={styles.header + ' noselect'}
                    data-testid="ProcessSideBar_header"
                    onMouseOver={(): void => {
                        if (!menuOpen && canChange) {
                            setHovering(true);
                        }
                    }}
                    onMouseLeave={(): void => {
                        setHovering(false);
                    }}
                    onClick={(): void => {
                        if (canChange) {
                            setMenuOpen(true);
                            setHovering(false);
                        }
                    }}
                    style={canChange ? { cursor: 'pointer' } : {}}
                >
                    <label
                        className={styles.titleLbl}
                        style={{
                            color: hovering
                                ? 'var(--contrast)'
                                : 'var(--accent)',
                            cursor: canChange ? 'pointer' : 'default',
                        }}
                    >
                        {process ? process.name : 'Sin Proceso'}
                    </label>
                    {canChange && (
                        <div
                            className={styles.editContainer}
                            style={{
                                color: hovering
                                    ? 'var(--contrast)'
                                    : 'var(--accent)',
                            }}
                        >
                            <EditRoundedIcon fontSize="inherit" />
                        </div>
                    )}
                </div>
                {process && process.visualElements.length > 0 && (
                    <div
                        className={styles.processContainer}
                        data-testid="ProcessSideBar_agentsContainer"
                    >
                        <ProcessRenderer ticket={ticket} process={process} />
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}
export default ProcessSideBar;
