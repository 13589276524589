import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Client,
    Company,
    Entity,
    EntityValue,
    Ticket,
} from '../../../../../../@Types/@Types';
import { updateCompany } from '../../../../../../controllers/CompaniesController/CompaniesService';
import ActionsService from '../../../../../../controllers/TicketsController/Actions/ActionsService';
import { refreshCurrent } from '../../../../../../controllers/TicketsController/TicketsActions';
import CompaniesMenu from '../../../../../../shared/@Menus/CompaniesMenu/CompaniesMenu';
import Dialog from '../../../../../../shared/Dialog/Dialog';
import { RootState } from '../../../../../../utils/_store';
import styles from './TicketCompany.module.css';
import SwapHorizRoundedIcon from '@material-ui/icons/SwapHorizRounded';
import { checkTicketPerm } from '../../../../../../utils/PermissionsFunctions';
import CompanyClientComponent from '../../../../../Companies/CompanyDetail/CompanyClientComponent/CompanyClientComponent';
import EntityRenderer from '../../../../../../shared/EntityRenderer/EntityRenderer';
interface TicketCompanyProps {
    ticket: Ticket;
    client: Client | null;
}

function TicketCompany({ ticket, client }: TicketCompanyProps): JSX.Element {
    const user = useSelector((state: RootState) => state.site.user);
    const entities = useSelector((state: RootState) => state.site.entities);

    const [showCompany, setShowCompany] = useState(false);
    const dispatch = useDispatch();
    const company = ticket.company;
    const companyRef = useRef<HTMLDivElement>(null);

    const canEdit = checkTicketPerm(user, ticket, null);
    if (company) {
        const entity = entities[company.idEntity];
        return (
            <React.Fragment>
                {showCompany && (
                    <Dialog
                        key={'CompanyMenu'}
                        maxWidth={'100%'}
                        disableEnforceFocus
                        onClose={(): void => {
                            setShowCompany(false);
                            dispatch(refreshCurrent());
                        }}
                    >
                        <CompanyDialog
                            canEdit={canEdit}
                            ticket={ticket}
                            client={client}
                            entity={entity}
                            company={company}
                            onSubmit={async (
                                entityValue,
                                reload
                            ): Promise<void> => {
                                await updateCompany(company._id, {
                                    values: entityValue.values,
                                });
                                dispatch(refreshCurrent());
                                reload();
                            }}
                        />
                    </Dialog>
                )}
                <span
                    onClick={(): void => {
                        setShowCompany(true);
                    }}
                    className={styles.company}
                    key={'Company'}
                >
                    {company.values.name ?? 'Sin Nombre'}
                </span>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                {showCompany && (
                    <CompaniesMenu
                        anchorRef={companyRef}
                        handleClose={(): void => {
                            setShowCompany(false);
                        }}
                        multiple={false}
                        confirm={'Seleccionar'}
                        handleConfirm={async (
                            company: Company
                        ): Promise<void> => {
                            try {
                                await ActionsService.setCompany(
                                    ticket.idProject,
                                    ticket._id,
                                    company._id
                                );
                                dispatch(refreshCurrent());
                                setShowCompany(false);
                            } catch (error) {
                                console.error(error);
                            }
                        }}
                    />
                )}
                <span
                    key={'Company'}
                    onClick={(): void => {
                        setShowCompany(true);
                    }}
                    className={styles.company + '  noselect'}
                    title={'Seleccionar Empresa'}
                    ref={companyRef}
                >
                    (Sin Empresa)
                </span>
            </React.Fragment>
        );
    }
}

export default TicketCompany;
interface CompanyDialogProps extends TicketCompanyProps {
    canEdit: boolean;
    entity: Entity;
    company: Company;
    onClose?: Function;
    onSubmit: (
        entity: Partial<EntityValue>,
        setLoading: Function
    ) => Promise<void>;
}
function CompanyDialog({
    canEdit,
    entity,
    client,
    company,
    ticket,
    onClose,
    onSubmit,
}: CompanyDialogProps): JSX.Element {
    const [showCompanyMenu, setShowCompanyMenu] = useState(false);
    const switchRef = useRef<HTMLDivElement>(null);
    const siteInfo = useSelector((state: RootState) => state.site);
    const dispatch = useDispatch();
    const [localClient, setLocalClient] = useState(client);

    useEffect(() => {
        setLocalClient(client);
    }, [client]);

    return (
        <div className={styles.container}>
            {showCompanyMenu && (
                <CompaniesMenu
                    zIndex={1301}
                    anchorRef={switchRef}
                    handleClose={(): void => {
                        setShowCompanyMenu(false);
                    }}
                    title="Cambiar Empresa"
                    elementsToOmit={[company]}
                    placement="bottom-end"
                    multiple={false}
                    confirm={'Cambiar'}
                    handleConfirm={async (company: Company): Promise<void> => {
                        try {
                            if (siteInfo.idProject) {
                                await ActionsService.setCompany(
                                    siteInfo.idProject,
                                    ticket._id,
                                    company._id
                                );
                            }
                            dispatch(refreshCurrent());
                            onClose?.();
                        } catch (error) {
                            console.error(error);
                        }
                    }}
                />
            )}

            {canEdit && (
                <div
                    className={styles.swapIcon}
                    ref={switchRef}
                    onClick={(): void => {
                        setShowCompanyMenu(true);
                    }}
                    title="Cambiar Empresa"
                >
                    <SwapHorizRoundedIcon fontSize="inherit" />
                </div>
            )}
            <div
                className={styles.entityContainer}
                style={{
                    width:
                        ((entity.size?.blockSize ?? 3) +
                            (entity.size?.spacingSize ?? 20)) *
                            (entity.size?.blockNum ?? 210) +
                        40,
                }}
            >
                <EntityRenderer
                    entity={entity}
                    entityValue={{ ...company, relationships: {} }}
                    customSend={onSubmit}
                    editable={false}
                />
            </div>
            {localClient &&
                localClient.companies.find(
                    (c) => c.idCompany === company._id
                ) && (
                    <React.Fragment>
                        <div className={styles.clientTitle}>Cliente:</div>
                        <div className={styles.companyClient}>
                            <CompanyClientComponent
                                client={localClient}
                                idCompany={company._id}
                                handleUpdate={(client: any): void => {
                                    setLocalClient(client);
                                }}
                            />
                        </div>
                    </React.Fragment>
                )}
        </div>
    );
}
