import React, { useState, useCallback, useEffect } from 'react';
import { nanoid } from 'nanoid';
import ReactFlow, {
    Background,
    BackgroundVariant,
    addEdge,
    removeElements,
    isEdge,
    Controls,
    useZoomPanHelper,
    useStore,
    useStoreActions,
    Edge,
    Node,
} from 'react-flow-renderer';
import * as ReactFlowRenderer from 'react-flow-renderer';
import NodeTypes from './Nodes/NodeTypes';
import DefaultEdge from './Styles/DefaultEdge/DefaultEdge';
import DefaultConnectionLine from './Styles/DefaultConnectionLine/DefaultConnectionLine';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Agent, Area, Flow } from '../../@Types/@Types';
import { DialogObjType } from './FlowsDialog/FlowsDialog';
import TransformationTypes from '../../constants/Flows/TransformationTypes';
import { getRawText } from '../../utils/draftFunctions';
import FlowNodeTypes from '../../constants/Flows/FlowNodeTypes';
import ErrorEdge from './Styles/ErrorEdge/ErrorEdge';
import ActionTypes from '../../constants/ActionTypes';
//Todo al salir debe salir el alert de si esta seguro de salir sin guardar.

interface FlowComponentProps {
    /** Current flow infor */
    flow: Flow;
    /** The flow's areas, undefined if it has none*/
    areas: Area[] | undefined;
    /** The flow's agents, undefined if it has none */
    agents: Agent[] | undefined;
    /** Current visual elements */
    elements: (Node<any> | Edge<any>)[];
    /** The object currently open in a dialog, undefined if no dialog is open */
    dialogObj: DialogObjType;
    /** Function that toggles the loader once the flow is loaded */
    setLoading: Function;
    /**  Function to update the current elements*/
    setElements: React.Dispatch<
        React.SetStateAction<(Node<any> | Edge<any>)[]>
    >;
    /** Function to update the currently displayed dialog */
    setDialogObj: React.Dispatch<React.SetStateAction<DialogObjType>>;
    /** Wrapper to obtain the react-flow bounds */
    reactFlowWrapper: React.MutableRefObject<any>;
    /** Ref to the reactflow instance */
    reactFlowInstance: any;
    /** Function to update the current react-flow instance */
    setReactFlowInstance: React.Dispatch<any>;
    /** String of the node to focus on, called on errors or on load. */
    nodeToFocus: string | undefined;
    /** Update the node to focus on it  */
    setNodeToFocus: Function;
}

function FlowsComponent({
    flow,
    areas,
    agents,
    history,
    elements,
    nodeToFocus,
    dialogObj,
    setLoading,
    setElements,
    setDialogObj,
    setNodeToFocus,
    reactFlowWrapper,
    reactFlowInstance,
    setReactFlowInstance,
}: RouteComponentProps & FlowComponentProps): JSX.Element {
    const [allowedConnection, setAllowedConnection] = useState<boolean>(true);
    const [firstTime, setFirstTime] = useState(false);
    /** React flow uses redux under the hood */
    /** Retrieves the setSelectedElements function from the react-flow redux actions */
    const setSelectedElements = useStoreActions(
        (actions) => actions.setSelectedElements
    );
    /**  this calles its the react-flow store to manipulate its elements */
    const FlowStore = useStore();
    /** Retrieves the react-flow function to center the user's view */
    const { setCenter } = useZoomPanHelper();

    /** If flow object changes reset the react-flow elements. */
    useEffect(() => {
        if (flow !== undefined) {
            resetFlow();
        }
    }, [flow]);

    /**
     * UseEffect that triggers the focusNode function when the props updates.
     */
    useEffect(() => {
        if (nodeToFocus !== undefined) {
            focusNode(nodeToFocus);
            setNodeToFocus(undefined);
        }
    }, [nodeToFocus]);

    /**
     * Function called to reset the flows elements.
     */
    const resetFlow = async (): Promise<void> => {
        const visualElements = flow.visualElements.map((element) => {
            if (
                element.type &&
                [
                    FlowNodeTypes.DECISION,
                    FlowNodeTypes.TRANSFORMATION,
                    FlowNodeTypes.ASSIGN,
                    FlowNodeTypes.AI,
                    FlowNodeTypes.COMMUNICATION,
                ].includes(element.type as FlowNodeTypes)
            ) {
                return {
                    ...element,
                    data: {
                        ...element.data,
                        openDialog: (): void => {
                            setDialogObj({
                                id: element.id,
                                type: element.type,
                            });
                            setSelectedElements([]);
                        },
                    },
                };
            } else {
                return element;
            }
        });
        setElements(visualElements);
        setFirstTime(true);
    };

    /**
     * Function that loads the react-flow and turns off the loader, this is only called the first time of the flow
     */
    useEffect(() => {
        if (reactFlowInstance && elements?.length > 0 && firstTime) {
            const urlParams = new URLSearchParams(window.location.search);
            const focus = urlParams.get('focus');
            //If the url specifies a focus query param it focuses on the element with that id or idFlowStep
            if (focus) {
                focusNode(focus);
                //Clears the queryparam
                urlParams.delete('focus');
                history.replace({
                    search: urlParams.toString(),
                });
            } else {
                reactFlowInstance.fitView();
            }
            setLoading(false);
            setFirstTime(false);
        }
    }, [reactFlowInstance, elements?.length, firstTime]);

    /**
     * Function called to focus visually on a node with the given id
     * @param nodeId the id of the node to focus
     */
    const focusNode = (nodeId: string): void => {
        const { nodes }: { nodes: any[] } = FlowStore.getState();
        const node = nodes.find(
            (n) => n.idFlowStep === nodeId || n.id === nodeId
        );
        if (node) {
            const x = node.__rf.position.x + node.__rf.width / 2;
            const y = node.__rf.position.y + node.__rf.height / 2;
            const zoom = 2.5;
            setCenter(x, y, zoom);
        } else {
            reactFlowInstance?.fitView();
        }
    };

    /**
     * Function called by react-flow once it has loaded.
     * This updates the reactFlowInstance
     */
    const onLoad = useCallback(
        (_reactFlowInstance) => {
            if (!reactFlowInstance) {
                setReactFlowInstance(_reactFlowInstance);
            }
        },
        [reactFlowInstance]
    );

    /**
     * Function called when react-flow
     */
    const onConnect = useCallback(
        (params) => {
            /** Check if no nodes have the same source.  */
            const singleSource =
                params.sourceHandle !== FlowNodeTypes.ASSIGN &&
                elements.find(
                    (elem) =>
                        isEdge(elem) &&
                        elem.source == params.source &&
                        params.sourceHandle == elem.sourceHandle
                ) === undefined;

            /** Check if connection is of type assign and targets Area or Agent */
            const isAssign =
                params.sourceHandle === FlowNodeTypes.ASSIGN &&
                params.targetHandle === FlowNodeTypes.AGENT;

            const isError = params.sourceHandle === 'ERROR';
            /** If any single source or assign connection, add it to the list of elements */
            if (singleSource || isAssign) {
                setElements((els) => {
                    return addEdge(
                        { ...params, type: isError ? 'error' : 'default' },
                        els
                    );
                });
            }
        },
        [elements]
    );

    /**
     * React-flow hook called when a connection starts
     * @param event Mouse event that started it
     * @param {nodeId, HandleType, handleId} source node info
     */
    const onConnectStart = (
        event: React.MouseEvent,
        { nodeId, handleType, handleId }: ReactFlowRenderer.OnConnectStartParams
    ): void => {
        if (handleType === 'source') {
            //check if source is not the same for 2 nodes, besides assign nodes
            const existingEdges =
                elements.find(
                    (elem) =>
                        isEdge(elem) &&
                        elem.source == nodeId &&
                        handleId == elem.sourceHandle
                ) === undefined;
            /** Toggle the allowedConnection state to show the red X con the connection */
            setAllowedConnection(
                existingEdges || handleId === FlowNodeTypes.ASSIGN
            );
        }
    };

    const onConnectStop = (): void => {
        setAllowedConnection(true);
    };

    const onElementsRemove = useCallback(
        (elementsToRemove: (Node | Edge)[]) => {
            /** If dialogObj is open dont delete, user types backspace on dialog open */
            if (dialogObj === undefined) {
                /** Delete all nodes except Entry and Exit */
                elementsToRemove = elementsToRemove.filter(
                    (element) => element.id !== 'ENTRY' && element.id !== 'EXIT'
                );
                setElements((els) => removeElements(elementsToRemove, els));
            }
        },
        [dialogObj]
    );

    /**
     * Hook to handle the node drag event
     * @param event Drag event
     */
    const onDragOver = (event: React.DragEvent): void => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };

    /**
     * Hook to handle the node drop event.
     * @param event NodeDrop
     */
    const onDrop = (event: React.DragEvent): void => {
        event.preventDefault();
        /** Props sent by the sidebar about the object dropped */
        const [type, relativeX, relativeY, objectId] = event.dataTransfer
            .getData('application/reactflow')
            .split(',');
        /** Check if values sent are valid  */
        if (
            NodeTypes[type] !== undefined &&
            !isNaN(+relativeX) &&
            !isNaN(+relativeY) &&
            reactFlowWrapper.current
        ) {
            //Create a unique id.
            let unique = false;
            let id: string = '';
            while (!unique) {
                id = nanoid().replace(/-/g, '_');
                unique =
                    elements.find((element) => element.id === id) === undefined;
            }
            const reactFlowBounds =
                reactFlowWrapper.current.getBoundingClientRect();
            /** Calc the relative position in the react flow grid */
            const position = reactFlowInstance.project({
                x: event.clientX - reactFlowBounds?.left - +relativeX + 60, //60px of sidebar width
                y: event.clientY - reactFlowBounds?.top - +relativeY + 54, //54px of navbar height
            });
            /** Create the node */
            const newNode: Node = {
                id,
                type,
                position,
            };
            const openDialog = (): void => {
                setDialogObj({ id, type });
                setSelectedElements([]);
            };
            if (type === FlowNodeTypes.AREA && areas) {
                newNode.data = areas.find((area) => area._id === objectId);
            } else if (type === FlowNodeTypes.AGENT && agents) {
                newNode.data = agents.find((agent) => agent._id === objectId);
            } else if (type === FlowNodeTypes.AI) {
                newNode.data = {
                    classify: true,
                    suggestReply: true,
                    openDialog,
                };
            } else if (type === FlowNodeTypes.COMMUNICATION) {
                newNode.data = {
                    name: 'Respuesta',
                    type: ActionTypes.AUTO_REPLY,
                    payload: {
                        files: [],
                        draft: getRawText(),
                        images: {},
                    },
                    openDialog,
                };
            } else if (type === FlowNodeTypes.ASSIGN) {
                newNode.data = {
                    type: 'AUTO',
                    openDialog,
                };
            } else if (type === FlowNodeTypes.DECISION) {
                newNode.data = {
                    name: 'Decisión',
                    decisions: [],
                    else: nanoid().replace(/-/g, '_'),
                    //TODO definir bien el label del else.
                    openDialog,
                };
            } else if (type === FlowNodeTypes.TRANSFORMATION) {
                newNode.data = {
                    name: 'Transformación',
                    type: TransformationTypes.AUTOMATIC,
                    openDialog,
                };
            }
            setElements((es) => es.concat(newNode));
        } else {
            //TODO bug de que llega sin tipo.
        }
    };

    return (
        <ReactFlow
            elements={elements}
            onConnectStart={onConnectStart}
            onConnectStop={onConnectStop}
            onConnect={onConnect}
            onElementsRemove={onElementsRemove}
            onLoad={onLoad}
            onDrop={onDrop}
            onDragOver={onDragOver}
            nodeTypes={NodeTypes}
            edgeTypes={{ default: DefaultEdge, error: ErrorEdge }}
            connectionLineComponent={(props): JSX.Element => (
                <DefaultConnectionLine
                    {...props}
                    allowedConnection={allowedConnection}
                />
            )}
            maxZoom={2.5}
            snapToGrid={true}
            snapGrid={[5, 5]}
            defaultZoom={1.5}
        >
            <Background variant={BackgroundVariant.Dots} size={0.5} />
            <Controls />
        </ReactFlow>
    );
}
export default withRouter(FlowsComponent);
