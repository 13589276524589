import styles from './SideBar.module.css';
import AgentsSideBar from './AgentsSideBar/AgentsSideBar';
import ClassifiersSideBar from './ClassifiersSideBar/ClassifiersSideBar';
import { Ticket } from '../../../../@Types/@Types';
import SubTicketsSideBar from './SubTicketsSideBar/SubTicketsSideBar';
import React, { useState } from 'react';
import Dialog from '../../../../shared/Dialog/Dialog';
import CreateSubTicket from './SubTicketsSideBar/CreateSubTicket/CreateSubTicket';
import ParentSideBar from './ParentSideBar/ParentSideBar';
import { useDispatch, useSelector } from 'react-redux';
import HeaderSideBar from './HeaderSideBar/HeaderSideBar';
import { refreshCurrent } from '../../../../controllers/TicketsController/TicketsActions';
import { RootState } from '../../../../utils/_store';
import { checkTicketPerm } from '../../../../utils/PermissionsFunctions';
import ResolutionDateSideBar from './ResolutionDateSideBar/ResolutionDateSideBar';
import EntitySideBar from './EntitySideBar/EntitySideBar';
import ProcessSideBar from './ProcessSideBar/ProcessSideBar';
import { useCurrentProject } from '../../../../hooks';
interface SideBarProps {
    /** The ticket currently being displayed */
    selectedElement: Ticket;
    /** If the layout is mobile */
    layoutIsMobile?: boolean;
}
function SideBar({
    selectedElement,
    layoutIsMobile,
}: SideBarProps): JSX.Element {
    const [createSubTicket, setCreateSubTicket] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.site.user);
    const organization = useSelector(
        (state: RootState) => state.site.organization
    );
    const project = useCurrentProject();
    const canChange = checkTicketPerm(user, selectedElement, null);
    return (
        <div
            className={
                layoutIsMobile ? styles.mobileContainer : styles.container
            }
        >
            <Dialog
                maxWidth="100%"
                open={createSubTicket}
                disableEnforceFocus
                onClose={(): void => setCreateSubTicket(false)}
            >
                <CreateSubTicket
                    close={(): void => {
                        setCreateSubTicket(false);
                    }}
                    parent={selectedElement}
                    handleCreated={(): void => {
                        dispatch(refreshCurrent());
                    }}
                ></CreateSubTicket>
            </Dialog>
            <div>
                <HeaderSideBar
                    layoutIsMobile={layoutIsMobile}
                    selectedElement={selectedElement}
                />
                <ResolutionDateSideBar
                    layoutIsMobile={layoutIsMobile}
                    selectedElement={selectedElement}
                />
                {Object.keys(project.processes).length > 0 && (
                    <React.Fragment>
                        <ProcessSideBar
                            ticket={selectedElement}
                            layoutIsMobile={layoutIsMobile}
                        />
                        <div
                            className={styles.hr}
                            style={{ marginBottom: 10 }}
                        />
                    </React.Fragment>
                )}
                <AgentsSideBar
                    canChange={canChange}
                    layoutIsMobile={layoutIsMobile}
                    selectedElement={selectedElement}
                />
                <div className={styles.hr} />
                <ClassifiersSideBar
                    layoutIsMobile={layoutIsMobile}
                    selectedElement={selectedElement}
                />
                {!selectedElement.path && (
                    <React.Fragment>
                        <div className={styles.hr} />
                        <SubTicketsSideBar
                            selectedElement={selectedElement}
                            setCreateSubTicket={setCreateSubTicket}
                        />
                    </React.Fragment>
                )}
                {selectedElement.father && (
                    <React.Fragment>
                        <div className={styles.hr} />
                        <ParentSideBar selectedElement={selectedElement} />
                    </React.Fragment>
                )}
                {project &&
                    project.entities
                        .filter(
                            (entity) =>
                                entity.idEntity !==
                                organization?.idCompanyEntity
                        )
                        .map((entity, index) => (
                            <EntitySideBar
                                projectEntity={entity}
                                canChange={canChange && entity.manual}
                                ticket={selectedElement}
                                layoutIsMobile={layoutIsMobile}
                                key={'E-' + index}
                            />
                        ))}
            </div>
        </div>
    );
}
export default SideBar;
