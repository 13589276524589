import styles from './AgentDetail.module.css';
import Avatar from '../../../shared/Avatar/Avatar';
import EditContainer from '../../../shared/EditContainer/EditContainer';
import { Agent } from '../../../@Types/Agent';
import ProjectPermissions from './ProjectPermissions/ProjectPermissions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../utils/_store';
import { useState, useRef, useEffect } from 'react';
import RoundedAutoComplete from '../../../shared/@Pickers/RoundedOrgAreaPicker/RoundedAutoComplete';
import {
    OrganizationPermissionsList,
    OrganizationWithoutCompaniesPermissionsList,
    OrganizationPermissions,
    EntityPermissionsList,
} from '../../../constants/Permissions';
import Toggle from '../../../shared/Toggle/Toggle';
import React from 'react';
import {
    transferTicketsToReplacement,
    updateAgentOnVacation,
    updateAgentPermissions,
} from '../../../controllers/AgentsController/AgentsService';
import { AgentPermissions } from '../../../@Types/Agent';
import { checkAdmin, checkOrgPerm } from '../../../utils/PermissionsFunctions';
import {
    updateOnVacation,
    updatePermissions,
} from '../../../controllers/AgentsController/AgentsActions';
import RoundedAgentPicker from '../../../shared/@Pickers/RoundedAgentPicker/RoundedAgentPicker';
import VanillaToast from '../../../shared/Toast/Toast';
import RoundedButton from '../../../shared/RoundedButton/RoundedButton';
import ConfirmDialog from '../../../shared/ConfirmDialog/ConfirmDialog';

interface AgentDetailProps {
    /** The selected Agent */
    selectedElement?: Agent;
    /** Function called when an agent's value needs updating */
    handleUpdate: Function;
    /** If the agents permissions should be shown */
    showPermissions?: boolean;
}
function AgentDetail({
    selectedElement,
    showPermissions = false,
    handleUpdate,
}: AgentDetailProps): JSX.Element {
    if (!selectedElement) {
        return <div></div>;
    }
    const dispatch = useDispatch();
    const siteInfo = useSelector((state: RootState) => state.site);
    const [currentPermissions, setCurrentPermissions] = useState(
        selectedElement.permissions ?? {
            organization: {},
            projects: {},
        }
    );
    const [showTransferDialog, setShowTransferDialog] = useState(false);
    const [toggleActive, setToggleActive] = useState(true);
    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (containerRef.current) containerRef.current.scrollTop = 0;
        setCurrentPermissions(
            selectedElement.permissions ?? {
                organization: {},
                projects: {},
            }
        );
    }, [selectedElement._id]);

    const saveChanges = async (
        newPermissions: AgentPermissions
    ): Promise<void> => {
        try {
            dispatch(updatePermissions(newPermissions));
            await updateAgentPermissions(selectedElement._id, newPermissions);
        } catch (error) {
            console.error(error);
        }
    };

    const renderEntityPermissions = (): JSX.Element[] => {
        const entities = Object.values(siteInfo.entities).filter(
            (entity) =>
                entity._id !== siteInfo.organization?.idClientEntity &&
                entity._id !== siteInfo.organization?.idCompanyEntity &&
                entity.active !== false
        );
        entities.sort((a, b) => a.name.localeCompare(b.name));

        return entities.map((entity) => (
            <div key={entity._id}>
                <label className={styles.orgLblName}>
                    {entity.pluralName}:
                </label>
                <div className={styles.orgPermissionsList}>
                    {EntityPermissionsList(entity).map((permission, index) => (
                        <div className={styles.orgPermission} key={index}>
                            <div
                                className={
                                    styles.orgPermissionLbl + ' noselect'
                                }
                            >
                                {permission.label}
                            </div>
                            <div className={styles.toggleContainer}>
                                <Toggle
                                    size="small"
                                    checked={
                                        currentPermissions.entities?.[
                                            entity._id
                                        ]?.[permission.key] === true
                                    }
                                    onChange={(checked: boolean): void => {
                                        const newPermissions = {
                                            ...currentPermissions,
                                            entities: {
                                                ...currentPermissions.entities,
                                                [entity._id]: {
                                                    ...(currentPermissions
                                                        .entities?.[
                                                        entity._id
                                                    ] ?? {}),
                                                    [permission.key]: checked,
                                                },
                                            },
                                        };
                                        setCurrentPermissions(newPermissions);
                                        saveChanges(newPermissions);
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        ));
    };

    return (
        <>
            {showTransferDialog && (
                <ConfirmDialog
                    onClose={(): void => setShowTransferDialog(false)}
                    onConfirm={async (element, setLoading): Promise<void> => {
                        setLoading(true);
                        try {
                            await transferTicketsToReplacement(
                                selectedElement?._id
                            );
                            setShowTransferDialog(false);
                        } catch (error) {
                            VanillaToast.create({
                                title: 'Error al transferir los casos',
                                text: error,
                                type: 'error',
                                timeout: 5000,
                            });
                        }
                        setLoading(false);
                    }}
                    title={'Transferir Casos'}
                    msg={
                        '¿Deseas transferir todos los casos de este agente a su reemplazo?'
                    }
                    btnMsg="Transferir"
                />
            )}
            <div
                className={styles.container}
                ref={containerRef}
                data-testid={'AgentDetail'}
            >
                <div className="flexbox">
                    <div className={styles.photoContainer}>
                        <div className={styles.avatarContainer}>
                            <Avatar
                                img={selectedElement.img}
                                lbl={selectedElement.name}
                                size={100}
                                fontSize={60}
                            ></Avatar>
                        </div>
                        <div
                            className={styles.nameContainer}
                            data-testid={'name'}
                        >
                            <EditContainer
                                object={selectedElement}
                                field={'name'}
                                handleConfirm={handleUpdate}
                            >
                                <label className={styles.nameLbl}>
                                    {selectedElement.name}
                                </label>
                                <input
                                    className={styles.nameInput + ' edit-input'}
                                    defaultValue={selectedElement.name}
                                    type="text"
                                    style={{ textAlign: 'center' }}
                                />
                            </EditContainer>
                        </div>
                    </div>
                    <div className={styles.basicInfoContainer}>
                        <p className={styles.label}>Correo</p>
                        <div
                            className={styles.valueContainer}
                            data-testid={'email'}
                        >
                            <p className={styles.value}>
                                {selectedElement.email}
                            </p>
                        </div>
                        <p className={styles.label}>Cargo</p>
                        <div
                            className={styles.valueContainer}
                            data-testid={'jobTitle'}
                        >
                            <EditContainer
                                object={selectedElement}
                                field={'jobTitle'}
                                handleConfirm={handleUpdate}
                            >
                                <p className={styles.value}>
                                    {selectedElement.jobTitle}
                                </p>
                                <input
                                    className={styles.jobInput + ' edit-input'}
                                    defaultValue={selectedElement.jobTitle}
                                    type="text"
                                />
                            </EditContainer>
                        </div>
                        <p className={styles.label}>Area Organizacional</p>
                        <div
                            className={styles.orgAreaContainer}
                            data-testid={'orgArea'}
                        >
                            <RoundedAutoComplete
                                value={selectedElement.orgArea}
                                handleUpdate={(value: string): void => {
                                    handleUpdate(
                                        selectedElement,
                                        'orgArea',
                                        value
                                    );
                                }}
                            />
                        </div>
                        <div className={styles.vacationsContainer}>
                            <label className={styles.vacationsLabel}>
                                Ausente:
                            </label>
                            <div data-testid={'onVacationToggle'}>
                                <Toggle
                                    size="small"
                                    checked={
                                        selectedElement.onVacations === true
                                    }
                                    disabled={!toggleActive}
                                    onChange={async (
                                        checked: boolean
                                    ): Promise<void> => {
                                        setToggleActive(false);
                                        try {
                                            dispatch(updateOnVacation(checked));
                                            await updateAgentOnVacation(
                                                selectedElement?._id,
                                                checked
                                            );
                                        } catch (error) {
                                            VanillaToast.create({
                                                title: 'Error al actualizar el agente',
                                                text: error,
                                                type: 'error',
                                                timeout: 5000,
                                            });
                                            dispatch(
                                                updateOnVacation(!checked)
                                            );
                                        }
                                        setToggleActive(true);
                                    }}
                                />
                            </div>
                        </div>
                        {selectedElement.onVacations && (
                            <div className={styles.replacement}>
                                <div
                                    className={styles.replacementContainer}
                                    data-testid={'replacement'}
                                >
                                    <RoundedAgentPicker
                                        multiple={false}
                                        value={
                                            selectedElement.replacement
                                                ? [selectedElement.replacement]
                                                : []
                                        }
                                        filterAgents={[selectedElement]}
                                        backgroundColor="transparent"
                                        label={'Remplazo'}
                                        handleUpdate={(agents): void => {
                                            handleUpdate(
                                                selectedElement,
                                                'replacement',
                                                agents[0] ?? null
                                            );
                                        }}
                                    />
                                </div>
                                {selectedElement.replacement && (
                                    <div className={styles.transferContainer}>
                                        <RoundedButton
                                            text={'Transferir Casos'}
                                            onClick={(): void =>
                                                setShowTransferDialog(true)
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        {checkAdmin(siteInfo.user) && (
                            <div className={styles.supportContainer}>
                                <label className={styles.supportLabel}>
                                    Servicio al Cliente:
                                </label>
                                <div>
                                    <Toggle
                                        size="small"
                                        checked={
                                            currentPermissions.support === true
                                        }
                                        disabled={!toggleActive}
                                        onChange={async (
                                            checked: boolean
                                        ): Promise<void> => {
                                            const newPermissions = {
                                                ...currentPermissions,
                                                support: checked,
                                            };
                                            setCurrentPermissions(
                                                newPermissions
                                            );
                                            saveChanges(newPermissions);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {showPermissions && (
                    <div className={styles.permissionsContainer}>
                        <label className={styles.permissionsLbl}>
                            Permisos
                        </label>
                        <label className={styles.permissionsDescr}>
                            * Para que los cambios de permisos se vean
                            reflejados, cierra sesión y vuelve a ingresar
                        </label>
                        <div className={styles.subtitlesContainer}>
                            {checkOrgPerm(
                                siteInfo.user,
                                siteInfo.organization,
                                OrganizationPermissions.PERMISSIONS
                            ) && (
                                <div className={styles.permissionListContainer}>
                                    <label className={styles.orgLblName}>
                                        Organizacionales:
                                    </label>
                                    <div
                                        className={styles.orgPermissionsList}
                                        data-testid={'orgPermissions'}
                                    >
                                        {((siteInfo.organization as any)
                                            ?.idCompanyEntity
                                            ? OrganizationPermissionsList
                                            : OrganizationWithoutCompaniesPermissionsList
                                        ).map((permission, index) => (
                                            <div
                                                className={styles.orgPermission}
                                                key={index}
                                            >
                                                <div
                                                    className={
                                                        styles.orgPermissionLbl +
                                                        ' noselect'
                                                    }
                                                >
                                                    {permission.label}
                                                </div>
                                                <div
                                                    className={
                                                        styles.toggleContainer
                                                    }
                                                    data-testid={
                                                        'permissionToggle'
                                                    }
                                                >
                                                    <Toggle
                                                        size="small"
                                                        checked={
                                                            currentPermissions
                                                                .organization?.[
                                                                permission.key
                                                            ] === true
                                                        }
                                                        onChange={(
                                                            checked: boolean
                                                        ): void => {
                                                            const newPermissions =
                                                                {
                                                                    ...currentPermissions,
                                                                    organization:
                                                                        {
                                                                            ...currentPermissions.organization,
                                                                            [permission.key]:
                                                                                checked,
                                                                        },
                                                                };
                                                            setCurrentPermissions(
                                                                newPermissions
                                                            );
                                                            saveChanges(
                                                                newPermissions
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {renderEntityPermissions()}
                                </div>
                            )}
                            <div className={styles.projectPermsContainer}>
                                {Object.values(siteInfo.projects)
                                    .filter(
                                        (project) =>
                                            checkOrgPerm(
                                                siteInfo.user,
                                                siteInfo.organization,
                                                OrganizationPermissions.PERMISSIONS
                                            ) ||
                                            siteInfo.user?.permissions
                                                .projects?.[project._id]
                                                ?.PERMISSIONS === true
                                    )
                                    .sort(
                                        (a, b) =>
                                            a.creation_date.getTime() -
                                            b.creation_date.getTime()
                                    )
                                    .map((project, index) => (
                                        <ProjectPermissions
                                            selectedElement={selectedElement}
                                            key={index}
                                            project={project}
                                            permissions={
                                                currentPermissions.projects?.[
                                                    project._id
                                                ] ?? {}
                                            }
                                            handleChange={(
                                                permissions
                                            ): void => {
                                                const newPermissions = {
                                                    ...currentPermissions,
                                                    projects: {
                                                        ...currentPermissions.projects,
                                                        [project._id]:
                                                            permissions,
                                                    },
                                                };
                                                setCurrentPermissions(
                                                    newPermissions
                                                );
                                                saveChanges(newPermissions);
                                            }}
                                        />
                                    ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
export default AgentDetail;
