import styles from './ProcessRenderer.module.css';
import { Ticket } from '../../@Types/@Types';
import {
    Process,
    ProcessVisualElement,
} from '../../@Types/ProcessTypes/Process';
import { useMemo } from 'react';
import { TicketProcess } from '../../@Types/TicketTypes/Ticket';
import ActivityStateTypes from '../../constants/ActivityTypes/ActivityStateTypes';
import { ActivityTypes } from '../../constants/ActivityTypes/ActivityTypes';
import ProcessElement from './ProcessItem/ProcessElement';

interface ProcessRendererProps {
    /** The ticket to display */
    ticket: Ticket;
    /** The process to display */
    process: Process;
}

interface CalculatedVisualElement extends ProcessVisualElement {
    progress: number | null;
    subElements: CalculatedVisualElement[];
}

/**
 * Generic Ticket Process Renderer
 */
function ProcessRenderer({
    ticket,
    process,
}: ProcessRendererProps): JSX.Element {
    const ticketProcess = ticket.process;
    if (!ticketProcess || !process) return <div></div>;
    const elements = useMemo(() => {
        return calcProgress(
            ticket,
            process.visualElements ?? [],
            ticketProcess
        );
    }, [ticket.process]);

    const elementsToRender = elements.filter((e) => !e.hidden || e.progress);
    return (
        <div className={styles.container}>
            {elementsToRender.map((element, index) => (
                <ProcessElement
                    key={index}
                    ticket={ticket}
                    process={process}
                    element={element}
                    last={index === elementsToRender.length - 1}
                />
            ))}
        </div>
    );
}

export default ProcessRenderer;

function calcProgress(
    ticket: Ticket,
    elements: ProcessVisualElement[],
    ticketProcess: TicketProcess
): CalculatedVisualElement[] {
    const prevs: string[] = [];
    return elements
        .map((e) => {
            const subElements = calcProgress(
                ticket,
                e.subElements ?? [],
                ticketProcess
            );
            let progress: number | null = 0;
            let idAction: string | undefined;
            if (
                subElements.length > 0 &&
                subElements.find(
                    (sub) => !sub.optional || (sub.progress && sub.progress > 0)
                )
            ) {
                for (const sub of subElements) {
                    if (sub.progress === null) {
                        progress = null;
                    } else if (progress !== null) {
                        progress += sub.progress;
                    }
                }
                if (progress !== null) {
                    progress = progress / subElements.length;
                }
                if (
                    progress === 0 &&
                    e.optional &&
                    ticket.state.type === 'CLOSED'
                )
                    return null;
                if (progress === 0 && e.partial) return null;
            } else {
                const executions = ticketProcess.activities[e.idActivity];
                const last = executions?.[executions?.length - 1];
                if (!last && e.partial) return null;
                if (!last && e.optional && ticket.state.type === 'CLOSED')
                    return null;
                if (last) {
                    switch (last.state) {
                        case ActivityStateTypes.COMPLETED:
                            idAction = (last as any).idAction;
                            progress = 100;
                            break;
                        // case ActivityStateTypes.ERROR:
                        case ActivityStateTypes.CANCELLED:
                            idAction = (last as any).idAction;
                            progress = null;
                            if (e.optional) return null;
                            break;
                        case ActivityStateTypes.IN_PROGRESS:
                            progress = 1;
                            switch (last.type) {
                                case ActivityTypes.DATE:
                                case ActivityTypes.TIME:
                                    const endDate = new Date(
                                        last.date
                                    ).getTime();
                                    const startDate = new Date(
                                        last.start_date
                                    ).getTime();
                                    const today = new Date().getTime();
                                    const q = Math.abs(today - startDate);
                                    const d = Math.abs(endDate - startDate);
                                    progress = Math.round((q / d) * 100);
                                    if (progress === 0 && today > startDate) {
                                        progress = 1;
                                    } else if (progress > 100) {
                                        progress = 99;
                                    }
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
            if (
                ticketProcess.current.find((act) =>
                    prevs.includes(act.idActivity)
                )
            ) {
                progress = 0;
            }
            prevs.push(e.idActivity);
            const element = { ...e, subElements, progress, idAction };
            return element;
        })
        .filter((e) => e) as CalculatedVisualElement[];
}
