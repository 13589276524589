import { Entity } from '../@Types/@Types';

export enum ProjectPermissions {
    REPLY = 'REPLY',
    CLOSE = 'CLOSE',
    CHANGE_STATE = 'CHANGE_STATE',
    CHANGE_PROCESS = 'CHANGE_PROCESS',
    CREATE_SUBTICKET = 'CREATE_SUBTICKET',
    CLASSIFY = 'CLASSIFY',
    CHANGE_RESOLUTION_DATE = 'CHANGE_RESOLUTION_DATE',
    CREATE_INTERNAL = 'CREATE_INTERNAL',
    EDIT_PROJECT = 'EDIT_PROJECT',
    EDIT_TEMPLATES = 'EDIT_TEMPLATES',
    ALL = 'ALL',
    ANALYTICS = 'ANALYTICS',
    MANAGE_NOT_ASSIGNED = 'MANAGE_NOT_ASSIGNED',
    PERMISSIONS = 'PERMISSIONS',
    EDIT_CLOSED = 'EDIT_CLOSED',
    VIEW = 'VIEW',
}

export const ProjectPermissionsList = [
    { key: ProjectPermissions.VIEW, label: 'Ver el Ambiente' },
    { key: ProjectPermissions.REPLY, label: 'Responder Casos' },
    {
        key: ProjectPermissions.CHANGE_STATE,
        label: 'Cambiar Estado de Casos',
    },
    {
        key: ProjectPermissions.CHANGE_RESOLUTION_DATE,
        label: 'Cambiar Fecha de Vencimiento de Casos',
    },
    {
        key: ProjectPermissions.CHANGE_PROCESS,
        label: 'Cambiar Proceso de Casos',
    },
    { key: ProjectPermissions.CLOSE, label: 'Cerrar Casos' },
    { key: ProjectPermissions.CLASSIFY, label: 'Clasificar Casos' },
    {
        key: ProjectPermissions.CREATE_INTERNAL,
        label: 'Crear Casos internamente',
    },
    {
        key: ProjectPermissions.CREATE_SUBTICKET,
        label: 'Crear SubCasos',
    },
    { key: ProjectPermissions.EDIT_PROJECT, label: 'Configurar el Ambiente' },
    {
        key: ProjectPermissions.EDIT_TEMPLATES,
        label: 'Configurar las Plantillas',
    },
    {
        key: ProjectPermissions.ANALYTICS,
        label: 'Ver la Analítica del Ambiente',
    },
    { key: ProjectPermissions.ALL, label: 'Ver todos los Casos' },
    {
        key: ProjectPermissions.MANAGE_NOT_ASSIGNED,
        label: 'Gestionar Casos no Asignados',
    },
    {
        key: ProjectPermissions.EDIT_CLOSED,
        label: 'Editar Casos Cerrados',
    },
    {
        key: ProjectPermissions.PERMISSIONS,
        label: 'Editar los Permisos del Ambiente',
    },
];

export enum OrganizationPermissions {
    VIEW = 'VIEW',
    COMPANIES = 'COMPANIES',
    CLIENTS = 'CLIENTS',
    CREATE_COMPANIES = 'CREATE_COMPANIES',
    EDIT_COMPANIES = 'EDIT_COMPANIES',
    AGENTS = 'AGENTS',
    PERMISSIONS = 'PERMISSIONS',
    EDIT_ORGANIZATION = 'EDIT_ORGANIZATION',
}

export const OrganizationPermissionsList = [
    { key: OrganizationPermissions.VIEW, label: 'Ver la Organización' },
    {
        key: OrganizationPermissions.CLIENTS,
        label: 'Ver los Clientes',
    },
    {
        key: OrganizationPermissions.AGENTS,
        label: 'Ver los Agentes',
    },
    {
        key: OrganizationPermissions.PERMISSIONS,
        label: 'Editar los Permisos Organizacionales',
    },
    {
        key: OrganizationPermissions.EDIT_ORGANIZATION,
        label: 'Configurar la Organización',
    },
    {
        key: OrganizationPermissions.COMPANIES,
        label: 'Ver las Empresas',
    },
    {
        key: OrganizationPermissions.EDIT_COMPANIES,
        label: 'Editar las Empresas',
    },
    {
        key: OrganizationPermissions.CREATE_COMPANIES,
        label: 'Crear una Empresa',
    },
];

export const OrganizationWithoutCompaniesPermissionsList = [
    { key: OrganizationPermissions.VIEW, label: 'Ver la Organización' },
    {
        key: OrganizationPermissions.AGENTS,
        label: 'Ver los Agentes',
    },
    {
        key: OrganizationPermissions.CLIENTS,
        label: 'Ver los Clientes',
    },
    {
        key: OrganizationPermissions.PERMISSIONS,
        label: 'Editar los Permisos Organizacionales',
    },
    {
        key: OrganizationPermissions.EDIT_ORGANIZATION,
        label: 'Configurar la Organización',
    },
];

export enum EntityPermissions {
    VIEW = 'VIEW',
    CREATE = 'CREATE',
    EDIT = 'EDIT',
}

export const EntityPermissionsList = (
    entity: Entity
): {
    key: EntityPermissions;
    label: string;
}[] => [
    {
        key: EntityPermissions.VIEW,
        label: `Ver ${entity.feminine ? 'las' : 'los'} ${entity.pluralName}`,
    },
    {
        key: EntityPermissions.EDIT,
        label: `Editar ${entity.feminine ? 'las' : 'los'} ${entity.pluralName}`,
    },
    {
        key: EntityPermissions.CREATE,
        label: `Crear ${entity.feminine ? 'una' : 'un'} ${entity.name}`,
    },
];

export const PROFILES: {
    name: string;
    description: string;
    PERMISSION?: OrganizationPermissions.PERMISSIONS;
    permissions: {
        projects: PartialRecord<ProjectPermissions, boolean> | '*';
        organization: PartialRecord<OrganizationPermissions, boolean> | '*';
    };
}[] = [
    {
        name: 'Colaborador',
        description:
            'Usuario que ayuda a resolver casos pero no puede interacturar con los clientes',
        permissions: {
            projects: {
                [ProjectPermissions.CHANGE_STATE]: true,
            },
            organization: {},
        },
    },
    {
        name: 'Agente',
        description: 'Usuario encargado de resolver y responder casos.',
        permissions: {
            projects: {
                [ProjectPermissions.CLOSE]: true,
                [ProjectPermissions.REPLY]: true,
                [ProjectPermissions.CHANGE_STATE]: true,
                [ProjectPermissions.CREATE_INTERNAL]: true,
            },
            organization: {},
        },
    },
    {
        name: 'Administrador de Ambiente',
        description: 'Usuario que administra los ambientes',
        PERMISSION: OrganizationPermissions.PERMISSIONS,
        permissions: {
            projects: '*',
            organization: {},
        },
    },
    {
        name: 'Administrador Global',
        description:
            'Usuario que administra toda la organizacion y todos los ambientes',
        PERMISSION: OrganizationPermissions.PERMISSIONS,
        permissions: {
            projects: '*',
            organization: '*',
        },
    },
];
type PartialRecord<K extends keyof any, T> = {
    [P in K]?: T;
};
